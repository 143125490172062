import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "@firebase/functions";
import { initializeApp } from "firebase/app";
import { ref, computed, onMounted, onUnmounted } from "vue";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "",
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL || "",
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.VUE_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp);

export const getUserState = () => {
  return new Promise((resolve, reject) => {
    return onAuthStateChanged(getAuth(), resolve, reject);
  });
};

export const auth = getAuth(firebaseApp);
export const useAuthState = () => {
  const user = ref(null);
  const error = ref(null);

  let unsubscribe;

  onMounted(() => {
    unsubscribe = onAuthStateChanged(
      auth,
      (u) => (user.value = u),
      (e) => (error.value = e)
    );
  });

  onUnmounted(() => {
    unsubscribe();
  });

  const isAuthenticated = computed(() => user.value != null);
  return { user, error, isAuthenticated, auth /* , storage */ };
};
