import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "crm",
    name: "crm",
    component: load("crm/View"),
    redirect: {
      name: "crm:orders",
      // name: "crm:clients",
    },
    meta: {
      requiresAuth: true,
      icon: "chat-circle",
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM],
      menuItems: "leftSideMenu.crm",
    },
    children: [
      {
        path: "clients",
        name: "crm:clients",
        component: load("crm/clients/Clients"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_CLIENTS],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
      {
        path: "push",
        name: "crm:push",
        component: load("crm/push/Push"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
      {
        path: "certificates",
        name: "crm:certificates",
        component: load("crm/certificates/Certificates"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },

      {
        path: "template",
        name: "crm:template",
        component: load("crm/template/Template"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
      {
        path: "trigger",
        name: "crm:trigger",
        component: load("crm/trigger/Trigger"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
      {
        path: "orders",
        name: "crm:orders",
        component: load("crm/orders/Orders"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_ORDERS],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
      {
        path: "kitchen",
        name: "crm:kitchen",
        component: load("crm/kitchen/Kanban"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_ORDERS],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },

      {
        path: "feedbacks",
        name: "crm:feedbacks",
        component: load("crm/feedbacks/Feedbacks"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_FEEDBACKS],
          icon: "chat",
          menuItems: "leftSideMenu.crm",
        },
      },
    ],
  },
  {
    path: "crm/certificate/create",
    name: "crm:certificate:create",
    component: load("crm/certificates/EditCertificate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM],
      icon: "chat",
    },
    menuItems: "leftSideMenu.crm",
  },
  {
    path: "crm/certificate/edit/:certificateId",
    name: "crm:certificate:edit",
    component: load("crm/certificates/EditCertificate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM],
      icon: "chat",
    },
    menuItems: "leftSideMenu.crm",
  },
  {
    path: "crm/clients/create",
    name: "new-client",
    component: load("crm/clients/EditClient"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_CLIENTS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/orders/create",
    name: "new-orders",
    component: load("crm/orders/EditOrder"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_ORDERS],
      icon: "chat",
      menuItems: "leftSideMenu.crm",
    },
  },
  {
    path: "",
    name: "edit-client",
    component: load("crm/clients/EditClientsView"),
    props: true,
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_CLIENTS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [
      {
        path: "crm/clients/edit/:clientId",
        name: "edit-client:info",
        component: load("crm/clients/EditClient"),
        props: true,
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_CLIENTS],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.crm",
        },
        children: [],
      },
      {
        path: "crm/clients/history/:clientId",
        name: "edit-client:history",
        component: load("crm/clients/EditClientHistory"),
        props: true,
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_CLIENTS],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.crm",
        },
        children: [],
      },
    ],
  },
  {
    path: "crm/push/create",
    name: "new-push",
    component: load("crm/push/EditPush"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/push/edit/:pushId",
    name: "edit-push",
    props: true,
    component: load("crm/push/EditPush"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/template/create",
    name: "crm-template",
    component: load("crm/template/EditTemplate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/template/edit/:templateId",
    name: "crm-edit-template",
    props: true,
    component: load("crm/template/EditTemplate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/trigger/edit/:triggerId",
    name: "edit-trigger",
    props: true,
    component: load("crm/trigger/EditTrigger"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
  {
    path: "crm/feedbacks/view/:feedbackId",
    name: "view-feedback",
    component: load("crm/feedbacks/NewFeedback"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_EDIT_FEEDBACK],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.crm",
    },
    children: [],
  },
];
