<template>
  <div v-click-outside="closeMenu" class="menu-item" @click="openMenu">
    <slot name="icon" />

    <div v-if="isOpen" class="sub-menu">
      <div class="sub-menu__top-row">
        <slot name="icon" />
        <div class="user-desc">
          <div class="user-name">
            <slot name="firstLast" />
          </div>
          <div class="user-role">
            <slot name="role" />
          </div>
          <div class="user-email">
            <slot name="email" />
          </div>
        </div>
      </div>
      <div v-for="item in items" :key="item" class="sub-menu__row" @click.stop="closeMenu">
        <router-link :to="{ name: item.name }" class="sub-menu__item" v-on="item.addClick ? { click: signOutUser } : {}"
          @click="setItem(item.title)">
          <v-icon :name="item.icon" height="24" width="24" class="fs1" />
          <div class="item-text">
            {{ $t(item.title) || "empty" }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    items: {
      type: Array,
      reqired: true,
      default: () => [{ name: "empty", title: "empty", path: "/", params: {} }],
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const signOutUser = async () => {
      try {
        store.dispatch("logout");
      } catch (e) {
        console.error(e.message);
      }
    };
    function setItem(text) {
      store.commit("SET_MENUITEM", text);
    }
    return { signOutUser, setItem };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openMenu() {
      this.isOpen = true;
    },
    closeMenu() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  position: relative;

  .sub-menu {
    display: flex;
    flex-direction: column;



    // &>*+* {
    //   margin-top: 12px;
    // }
    margin-top: 10px;
    justify-content: space-between;
    position: absolute;
    top: 50px;
    right: 5px;
    min-width: max-content;
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    // padding: 12px 8px;
    z-index: 10;

    &__top-row {
      height: 56px;
      padding: 8px 10px;
      display: flex;

      &>*+* {
        margin-left: 10px;
      }

      font-weight: 400;
      line-height: 130%;

      border-bottom: 1px solid #ececec;
    }

    &__row {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        margin-top: 10px;
        // background-color: rgba(189, 80, 60, 0.2);
      }

      &>*+* {
        margin-left: 10px;
      }

      // padding: 12px 8px;
      line-height: 32px;
      // height: 40px;

      &:hover {
        background: rgba(0, 205, 215, 0.1);
        border-radius: 8px;
      }
    }
  }

  .sub-menu__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;

    color: #595a5b;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px;

    .item-text {
      display: inline-block;
    }

    &>*+* {
      margin-left: 10px;
    }
  }
}

.user-name {
  font-size: 16px;
  color: #595a5b;
}

.user-role,
.user-email {
  font-size: 12px;
  color: #9b9b9b;
}
</style>
