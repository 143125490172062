<script setup>
import { VueFinalModal } from "vue-final-modal";

// eslint-disable-next-line no-undef
const emit = defineEmits({
  confirm: null,
});
</script>

<template>
  <vue-final-modal
    class="resolution-alert"
    overlay-class="resolution-alert__overlay"
  >
    <div class="mobile-warning-screen">
      <div class="mobile-warning-screen__content">
        <div class="mobile-warning-screen__icons">
          <v-icon name="laptop"
width="52"
height="52"
stroke="none" />
          <div class="mobile-warning-screen__icons--text">или</div>
          <v-icon name="tablet"
width="52"
height="52"
stroke="none" />
        </div>

        <div class="mobile-warning-screen__text-block">
          Для корректной работы <strong>Панели управления</strong>,
          воспользуйтесь устройством, с разрешением экрана не ниже 1024×768
          пикселей.
        </div>
        <div class="mobile-warning-screen__buttons">
          <action-button action
@click="emit('confirm')">
            Понятно
          </action-button>
        </div>
      </div>
      <div class="mobile-warning-screen__close">
        <v-icon
          name="x"
          width="24"
          height="24"
          stroke="#0E0E22"
          @click="emit('confirm')"
        />
      </div>
    </div>
  </vue-final-modal>
</template>
<style lang="scss">
.resolution-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  &__overlay {
    background: rgba(14, 14, 34, 0.8) !important;
    backdrop-filter: blur(2px) !important;
  }
}
.mobile-warning-screen {
  position: relative !important;
  background: #ffffff;
  border-radius: 20px;
  padding: 32px 24px;
  position: relative;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  &__icons {
    display: flex;
    gap: 12px;
    justify-content: center;
    &--text {
      color: #bcbbbb;
      font: 400 12px/130% "Inter", sans-serif;
      text-align: center;
      line-height: 52px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text-block {
    text-align: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 320px;
  }
  &__close {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    cursor: pointer;
  }
}
</style>
