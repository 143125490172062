import { computed } from "vue";
import store from "@/store";
import axiosInstance from "./axios.service";

const userid = computed(() => store.getters.userid);

export async function updateProgress() {
  const url = `/operator/merchant/config`;
  const progress = store.getters.get_progress;
  try {
    const newProgress = (+(progress || 0) + 1);
    await axiosInstance.put(url, {
      progress: newProgress,
    });
    store.dispatch("SET_PROGRESS", newProgress);
  } catch (error) {
    console.log("error:", error);
  }
}

export async function getCurrentStep() {
  const steps = [
    {
      step: "main",
      header: "Добро пожаловать в qbeek!",
      desc: "Добро пожаловать в qbeek! Для корректной работы приложения необходимо настроить основные настройки.",
    },
    {
      step: "wallet",
      header: "Настроить карту Wallet",
      desc: 'Для корректной работы приложения необходимо настроить карту Wallet."',
    },
    {
      step: "pselection",
      header: "Настроить программу лояльности",
      desc: 'Для корректной работы приложения необходимо настроить программу лояльности.',
    },
    {
      step: "points:list",
      header: "Добавить точку продаж",
      desc: 'Отлично! Вы настроили вашу программу лояльности, теперь добавьте свою первую точку продаж.',

    },
    {
      step: "catalog:settings",
      header: "Настройка Онлайн-меню",
      desc: 'Отлично! Вы добавили вашу точку продаж, теперь заполните ваш онлайн-каталог.',
    },
    // {
    //   step: "catalog:qrcode",
    //   header: "Настройка Внешнего вида меню",
    //   desc: 'Для корректной работы приложения необходимо настроить внешний вид меню.',
    // }
  ];

  return steps[store.getters.get_progress];
}
