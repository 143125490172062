<template>
  <div :class="['text-input', { h37 }]">
    <label class="text-input__label-wrapper">
      <div v-if="noTitle" :class="['text-input__label', { aqsi: classAqsi }]">
        {{ title }}
      </div>
      <input
        v-bind="$attrs"
        v-model.trim="textInputValue"
        :class="['text-input__input', { h37, noRoundRight }]"
        :type="inputType"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        @paste="handlePaste"
      />
    </label>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: { type: [String, Number, Object], default: "" },
  title: { type: String, default: "" },
  placeholder: { type: String, default: "" },
  disabled: { type: Boolean, default: false },
  noTitle: { type: Boolean, default: false },
  classAqsi: { type: Boolean, default: false },
  inputType: { type: String, default: "text" },
  h37: { type: Boolean, default: false },
  noPaste: { type: Boolean, default: false },
  alphanumeric: { type: Boolean, default: false },
  noRoundRight: { type: Boolean, default: false },
});

const emit = defineEmits(["update:modelValue"]);

const textInputValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (props.alphanumeric) {
      emit("update:modelValue", value.replace(/[^a-zA-Z0-9]/g, ""));
    } else {
      emit(
        "update:modelValue",
        typeof value === "string" ? value.trim() : value
      );
    }
  },
});

// const handlePaste = (event) => {
//   if (props.noPaste) {
//     event.preventDefault();
//     return;
//   }
//   if (event.target.type === "text") {
//     const pastedText = event.clipboardData.getData("text");
//     const selectionStart = event.target.selectionStart;
//     const selectionEnd = event.target.selectionEnd;
//     const newValue =
//       textInputValue.value?.substring(0, selectionStart) +
//       pastedText +
//       textInputValue.value?.substring(selectionEnd);
//     const newCursorPosition = selectionStart + pastedText.length;
//     event.target.setSelectionRange(newCursorPosition, newCursorPosition);
//     textInputValue.value = newValue;
//     // emit("update:modelValue", textInputValue.value);
//     event.preventDefault();
//   }
// };
const handlePaste = (event) => {
  if (props.noPaste) {
    event.preventDefault();
    return;
  }
  if (event.target.type === "text" || event.target.tagName === "TEXTAREA") {
    const pastedText = event.clipboardData?.getData("text");
    if (!pastedText) return;

    // Ensure `textInputValue.value` is a string
    const currentValue = textInputValue.value || "";
    if (!currentValue.substring) return;
    const selectionStart = event.target.selectionStart ?? 0;
    const selectionEnd = event.target.selectionEnd ?? selectionStart;

    const newValue =
      currentValue.substring(0, selectionStart) +
      pastedText +
      currentValue.substring(selectionEnd);

    textInputValue.value = newValue;

    // Update the cursor position
    const newCursorPosition = selectionStart + pastedText.length;
    requestAnimationFrame(() => {
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    });

    // Emit updated value
    textInputValue.value = newValue;

    event.preventDefault();
  }
};
</script>

<style lang="scss" scoped>
.text-input {
  width: 100%;
  height: 45px;
  &__label-wrapper {
    width: 100%;
    text-align: left;
  }
  &__label {
    font-size: 12px;
    line-height: 130%;

    color: #9b9b9b;
    margin-bottom: v-bind(mb);
  }
  &__input {
    width: 100%;
    border: 1px solid #e7e7e9;
    border-radius: 8px;
    padding: 0px 16px;
    height: 45px;
    color: #0e0e22 !important;
    font: 400 14px/130% "Inter", sans-serif;
    text-align: left;
    position: relative;
    outline: none;
    &:not(:placeholder-shown) {
      border-color: #a0a0a0;
    }
    &:focus {
      border-color: #00cdd7;
    }
    &:active {
      border-color: rgba(0, 205, 215, 0.5);
    }
    /* &:placeholder-shown {
      border-color: #d0d0d0;
    } */
    &::placeholder {
      text-transform: none !important;
    }

    &:-ms-input-placeholder {
      text-transform: none !important;
    }

    &::-ms-input-placeholder {
      text-transform: none !important;
    }

    &[readonly] {
      border-color: #e7e7e9;
      color: #bcbbbb !important;
      cursor: not-allowed;
    }
  }
  &:hover {
    background: #ffffff;
  }
}

.placeholder-none {
  &::placeholder {
    text-transform: none !important;
  }

  &:-ms-input-placeholder {
    text-transform: none !important;
  }

  &::-ms-input-placeholder {
    text-transform: none !important;
  }
}
[disabled] {
  background: #eeeff2;
  color: #bcbbbb !important;
  border: none !important;
  outline: none;
  cursor: auto;
}
.aqsi {
  &::placeholder {
    color: #0e0e22 !important;
  }

  &:-ms-input-placeholder {
    color: #0e0e22 !important;
  }

  &::-ms-input-placeholder {
    color: #0e0e22 !important;
  }
}
.h37 {
  height: 37px;
  border-radius: 12px;
}
.noRoundRight {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
