import store from "@/store";
import { getCurrentStep } from "@/services/guide.service";
import axiosInstance from "@/services/axios.service";
import i18n from "@/i18n";
import { useModal } from "vue-final-modal";
import GuideStepRedirect from "@/components/UI/modals/GuideStepRedirect.vue";

export async function defaultRedirectMiddleware(to, from, next) {
  // Игнорируем редирект для неавторизованных роутов
  const publicRoutes = ['login', 'register', 'forgot-password', 'main'];
  if (publicRoutes.includes(to.name)) {
    return next();
  }

  // Игнорируем редирект для системных и служебных роутов
  const ignoredRoutes = [
    'profile',
    'error',
    'error-404',
    'error-403',
    'support',
    'faq:default',
    'points',
  ];

  // Игнорируем дочерние роуты основных разделов
  const childRoutes = ['bonus', 'discount', 'edit-product', 'new-product', 'new-point', 'edit-point'];
  console.log("to.name", to.name);

  if (ignoredRoutes.includes(to.name) || childRoutes.includes(to.name)) {
    return next();
  }

  console.log("defaultRedirectMiddleware");

  // Получаем прогресс с сервера или из store
  let userProgress = store.getters.get_progress;

  if (userProgress === null || userProgress === undefined) {
    const urlRead = `/qbeek/merchant/config`;
    const currentProgress = await axiosInstance.get(urlRead);
    console.log("currentProgress", currentProgress);

    userProgress = currentProgress?.progress || 0;
    store.dispatch("SET_PROGRESS", userProgress);
  }

  // Получаем текущий шаг настройки
  const currentStep = await getCurrentStep();
  console.log("currentStep", currentStep);
  console.log("store.getters.get_progress", store.getters.get_progress);
  console.table({
    'store.getters.get_progress': store.getters.get_progress,
    'userProgress': userProgress,
    'to.name': to.name,
    'currentStep': currentStep?.step
  });

  if (userProgress <= 6 && currentStep?.step && to.name !== currentStep?.step) {
    console.log("to.name", to.name);
    console.log(`Redirecting to ${currentStep.step} (progress: ${userProgress})`);

    // Show modal before redirect
    const modal = useModal({
      component: GuideStepRedirect,
      slots: {
        header: currentStep.header,
        desc: currentStep.desc
      }
    });

    modal.open();

    return next({
      name: currentStep.step,
      params: { locale: i18n.global.locale }
    });
  }

  return next();
}
