import axiosInstance from "./axios.service";

export const deleteDocInCollection = async (collectionName, id) => {
  const url = `/qbeek/merchant/${collectionName}/delete/${id}`;
  try {
    const response = await axiosInstance.delete(url);
    return response.ok;
  } catch (error) {
    console.error("deleteDocInCollection ==> ", error.message);
  }
};
// TODO: Deprecate NEED REALISED
export const fetchCollectionsSubscription = async () => {
  const url = `/qbeek/merchants/all`;
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error("Error fetching collection:", error);
  }
};
export const getUserData = async (userRole) => {
  const url = `/operator/personal/list`;
  try {
    const querySnapshot = await axiosInstance.get(url);
    const target = [];
    // console.log('querySnapshot', querySnapshot);

    querySnapshot.map((docdata) => {
      if (userRole?.value === "admin" && docdata?.role?.value !== "admin" && docdata?.disabled !== true) {
        target.push({
          ...docdata,
          phoneNumber:
            docdata?.phones?.map((phone) => phone.number).join(";") || "",
          searchRole: docdata?.role?.label,
          fullname: [
            docdata?.firstName,
            docdata?.patrpatronymic || "",
            docdata?.lastName,
          ]
            .join(" ")
            .replace(/\s+/, " "),
        });
      }
      if (
        userRole?.value === "operator" &&
        docdata?.role?.value === "employee"
      ) {
        target.push({
          ...docdata,
        });
      }
    });
    console.log('target', target);
    return target;
  } catch (error) {
    console.error(error);
  }
};
/* ========================= */
