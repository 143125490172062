<template>
  <vue-final-modal class="resolution-alert" overlay-class="resolution-alert__overlay" background="non-interactive"
    :click-to-close="true" :esc-to-close="true">
    <div class="add-balance">
      <div class="content">
        <div class="header">Баланс</div>
        <div class="header-balance">
          <div class="balance-accesse">
            <div class="balance-accesse__title">Доступно </div>
            <div class="balance-accesse__value">1790,35 ₽</div>
          </div>
          <div class="balance-reserve">
            <div class="balance-reserve__title">Зарезервировано</div>
            <div class="balance-reserve__value">0,00 ₽</div>
          </div>
        </div>
        <!-- <div class="desc">Пополнить депозит клубной карты.</div> -->
      </div>
      <section class="cell-item__wrapper">
        <p class="cell-item__title">Пополнить Баланс:</p>
        <ErrorCheck :is-error="v$?.userID?.$error" :error-message="v$?.userID?.$errors[0]?.$message"
          :error-object="v$.userID">
          <text-input v-model="deposit.userID" no-title placeholder="Банковской переводом по счёту"
            @focus="v$.$reset()" />
        </ErrorCheck>
      </section>
      <section class="cell-item__wrapper">
        <action-button w100p>Банковской карой или СБП</action-button>
      </section>
      <section class="cell-item__wrapper">
        <p class="cell-item__title">Введите сумму:</p>
        <ThousandInput v-model="sumToBalance" no-title placeholder="Введите сумму" />
      </section>
      <section class="section-edit__row">
        <div class="ex-btn-wraper">
          <div v-for="sum in plusSums" :key="sum" :class="['ex-btn', { 'ex-btn--active': sumToBalance === sum }]"
            @click="addToInput(sum)">
            {{ formatNumber(sum) }}
          </div>
        </div>
        <Cell>&nbsp;</Cell>
      </section>
      <div class="buttons">
        <action-button class="back" @click="() => emit('close')">
          Назад
        </action-button>
        <action-button action class="add" @click="saveTopUpDeposit">
          Получить счёт
        </action-button>
      </div>
      <svg class="x-close" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        @click="emit('close')">
        <path d="M18.9961 6L6.99609 18M6.99609 6L18.9961 18" stroke="#0E0E22" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </vue-final-modal>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
// import { useRouter } from "vue-router";
import { VueFinalModal } from "vue-final-modal";
import Cell from "@/components/app/rows/Cell.vue";
import TextInput from "@/components/UI/inputs/TextInput.vue";
import ThousandInput from "@/components/UI/inputs/ThousandInput.vue";
import { required, helpers, not, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { updateDeposit } from "@/services/loyalty.service";
import i18n from "@/i18n";

import { useToast } from "vue-toastification";
const toast = useToast();
const { withAsync, withMessage } = helpers;
const currentCurrency = ref({ currencySign: "" });
const deposit = reactive({
  userID: null,
  amount: null,
});
const userIDNotEmpty = computed(() => "ID не может быть пустым");
const amountNotEmpty = computed(() => "Сумма не может быть пустой");
const amountNotZero = computed(() => "Сумма не может быть нулевой");
const idNotInStates = computed(() => "Данный ID отсутствует в базе");

const rules = computed(() => {
  return {
    userID: {
      required: withMessage(userIDNotEmpty, required),
    },
    amount: {
      required: withMessage(amountNotEmpty, required),
      not: withMessage(amountNotZero, not(sameAs(0))),
    },
  };
});

const plusSums = ref([500, 1000, 3000, 5000, 10000]);
function formatNumber(number) {
  return Number(number).toLocaleString(i18n.global.locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
const sumToBalance = ref(0);
function addToInput(sum) {
  sumToBalance.value = sum;
}
const v$ = useVuelidate(rules, deposit);
async function saveTopUpDeposit() {
  // v$.value.$reset();
  const resultValidate = await v$.value.$validate();
  if (!resultValidate) return;
  await updateDeposit({ userId: deposit.userID, amount: +deposit.amount });
  toast.success(`Депозит обновлён.`);
  emit("confirm", deposit);
}
// eslint-disable-next-line no-undef
defineProps({});
// const router = useRouter();
// eslint-disable-next-line no-undef
const emit = defineEmits({
  close: null,
  confirm: null,
});
onMounted(async () => { });
</script>

<style lang="scss" scoped>
.add-balance,
.add-balance * {
  box-sizing: border-box;
}

.add-balance {
  background: var(--maincolors-white, #ffffff);
  border-radius: 24px;
  padding: 32px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  /* width: 420px; */
  position: relative;
  box-shadow: var(--shadow-medium-box-shadow,
      0px 4px 40px 0px rgba(0, 0, 0, 0.08));
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.header {
  color: var(--maincolors-header, #0e0e22);
  text-align: center;
  font: var(--dashboard-h-1, 600 20px/110% "Montserrat", sans-serif);
  position: relative;
  align-self: stretch;
}

.desc {
  color: var(--maincolors-text, #595a5b);
  text-align: center;
  font: var(--dashboard-textmd, 400 14px/130% "Inter", sans-serif);
  position: relative;
  align-self: stretch;
}

.buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}

.x-close {
  flex-shrink: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  overflow: visible;
  cursor: pointer;
}

.back,
.add {
  flex: 1;
}

.header-balance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  font: var(--dashboard-textmd, 400 20px/22px "Montserrat", sans-serif);
}

.balance-accesse,
.balance-reserve {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}


.balance-accesse__value,
.balance-reserve__value {
  font-weight: 600;
}

.cell-item__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;


}

.ex-btn-wraper {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}

.ex-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a0a0a0;
  border-radius: 8px;
  padding: 8px 16px;
  //width: 100px;
  flex: 1;
  height: 32px;
  font: 400 12px / 130% "Inter", sans-serif;
  color: #a0a0a0;
  cursor: pointer;

  /* margin-left: 10px; */
  &--active {
    border: 1.5px solid #595a5b;
    background-color: #595a5b;
    color: #fff;
  }
}

.ex-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a0a0a0;
  border-radius: 8px;
  padding: 8px 16px;
  //width: 100px;
  flex: 1;
  height: 32px;
  font: 400 12px / 130% "Inter", sans-serif;
  color: #a0a0a0;
  cursor: pointer;

  /* margin-left: 10px; */
  &--active {
    border: 1.5px solid #595a5b;
    background-color: #595a5b;
    color: #fff;
  }
}
</style>
