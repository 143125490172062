import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "broadcast",
    name: "broadcast",
    component: load("broadcast/View"),
    redirect: {
      name: "broadcast:stat",
    },
    meta: {
      requiresAuth: true,
      icon: "chat-circle",
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM],
      menuItems: "leftSideMenu.broadcast",
    },
    children: [
      {
        path: "stat",
        name: "broadcast:stat",
        component: load("broadcast/statistic/Statistic"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.broadcast",
        },
      },
      {
        path: "push",
        name: "broadcast:push",
        component: load("broadcast/push/Push"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.broadcast",
        },
      },
      {
        path: "template",
        name: "broadcast:template",
        component: load("broadcast/template/Template"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.broadcast",
        },
      },
      {
        path: "trigger",
        name: "broadcast:trigger",
        component: load("broadcast/trigger/Trigger"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
          icon: "chat",
          menuItems: "leftSideMenu.broadcast",
        },
      },
    ],
  },
  {
    path: "broadcast/push/create",
    name: "new-push",
    component: load("broadcast/push/EditPush"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.broadcast",
    },
    children: [],
  },
  {
    path: "broadcast/push/edit/:pushId",
    name: "edit-push",
    props: true,
    component: load("broadcast/push/EditPush"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.broadcast",
    },
    children: [],
  },
  {
    path: "broadcast/template/create",
    name: "broadcast:new-template",
    component: load("broadcast/template/EditTemplate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.broadcast",
    },
    children: [],
  },
  {
    path: "broadcast/template/edit/:templateId",
    name: "broadcast:edit-template",
    props: true,
    component: load("broadcast/template/EditTemplate"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.broadcast",
    },
    children: [],
  },
  {
    path: "broadcast/trigger/edit/:triggerId",
    name: "broadcast:edit-trigger",
    props: true,
    component: load("broadcast/trigger/EditTrigger"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CRM_PUSH],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.broadcast",
    },
    children: [],
  },
];
