/* eslint-disable no-prototype-builtins */
import moment from "moment";
import { ref } from "vue";
import { useFetch } from "@vueuse/core";
import { currency, countryCurrency } from "@/constants/index";

import store from "@/store";
import axiosInstance from "./axios.service";
import { getPointsData } from "./points.service";

/* === Get DATA Collection in Merchant === */
export const getDataCollectionInMerchant = async (collectionName) => {
  const url = `/qbeek/merchant-sub/${collectionName}/all`;
  try {
    const snapshot = await axiosInstance.get(url);
    return snapshot
      .map((t) => {
        if (collectionName === "tasks") {
          t["dateEnd"] = moment(t["dateEnd"]).toDate();
          t["dateStart"] = moment(t["dateStart"]).toDate();
        }
        return {
          ...t,
        };
      })
      .filter((it) => !it?.disabled);
  } catch (error) {
    console.log(
      `getDataCollectionInMerchant:${collectionName}:fnFirebase.js ===>>> `,
      error
    );
  }
};
export const getDataCollectionInMerchantOneItem = async (
  collectionName,
  id
) => {
  try {
    const target = {};
    const url = `/qbeek/merchant-sub/${collectionName}/read/${id}`;
    const temp = await axiosInstance.get(url);
    if (!temp) return null;
    for (const key in temp) {
      if (
        key === "date" &&
        // eslint-disable-next-line no-prototype-builtins
        (temp[key]?.hasOwnProperty("start") || temp[key]?.hasOwnProperty("end"))
      ) {
        // eslint-disable-next-line no-prototype-builtins
        if (temp[key]?.hasOwnProperty("start")) {
          target[key]["start"] = temp[key]["start"]?.toDate();
        }
        if (temp[key]?.hasOwnProperty("end")) {
          target[key]["end"] = temp[key]["end"]?.toDate();
        }
      } else if (key === "time") {
        if (temp[key]?.hasOwnProperty("start")) {
          target[key]["start"] = temp[key]["start"]?.toDate();
        }
        if (temp[key]?.hasOwnProperty("end")) {
          target[key]["end"] = temp[key]["end"]?.toDate();
        }
      } else if (key === "birthday") {
        target[key] = moment(temp[key]).toDate();
      } else if (key === "dateSent") {
        target[key] = moment(temp[key]).toDate();
      } else if (key === "dateStart" || key === "dateEnd") {
        target[key] = moment(temp[key]).toDate();
      } else {
        target[key] = temp[key];
      }
    }
    return target;
  } catch (error) {
    console.log(
      `getDataCollectionInMerchantOneItem:${collectionName}:${id}: => `,
      error
    );
  }
};

export async function saveDataCollectionInMerchant(
  target,
  collectionName,
  docId
) {
  if (!docId) {
    const url = `/qbeek/merchant-sub/${collectionName}/create`;
    try {
      const response = await axiosInstance.post(url, target);
      return response;
    } catch (error) {
      console.error("saveDataCollectionInMerchant", error.message);
    }
  } else {
    const url = `/qbeek/merchant-sub/${collectionName}/update/${docId}`;
    try {
      const response = await axiosInstance.post(url, target);
      return response;
    } catch (error) {
      console.error("saveDataCollectionInMerchant", error.message);
    }
  }
}

export const updateDataCollectionInMerchant = async (
  target,
  collectionName
) => {
  const url = `/qbeek/merchant-sub/${collectionName}/update/${target.id}`;
  try {
    const response = await axiosInstance.post(url, target);
    return response;
  } catch (error) {
    console.error("updateDataCollectionInMerchant", error.message);
  }
};

export const updateDataFieldCollectionInMerchant = async (
  target,
  collectionName
) => {
  const url = `/qbeek/merchant-sub/${collectionName}/update/${target.id}`;
  try {
    const response = await axiosInstance.post(url, target);
    return response;
  } catch (error) {
    console.error("updateDataCollectionInMerchant", error.message);
  }
};

export const deleteDataCollectionInMerchant = async (collectionName, id) => {
  const url = `/qbeek/merchant-sub/${collectionName}/delete/${id}`;
  try {
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    console.error("deleteDataCollectionInMerchant ==> ", error.message);
  }
};
export const disabledDataCollectionInMerchant = async (id, collectionName) => {
  const url = `/qbeek/merchant-sub/${collectionName}/update/${id}`;

  try {
    const response = await axiosInstance.post(url, { disabled: true });
    return response;
  } catch (error) {
    console.error("deleteDataCollectionInMerchant ==> ", error.message);
  }
};
export const updateDateSubscriptionInMerchants = async () => {
  try {
    const currentDate = moment();
    const futureDate = currentDate.add(1, "month").subtract(1, "day").toDate();
    const url = `/v1/qbeek/merchant/update`;
    const response = await axiosInstance.post(url, {
      endOfSubscription: futureDate,
    });
  } catch (error) {
    console.error("Error fetching collection:", error.message);
  }
};
export const updateStatusSubscriptionInMerchants = async (id = "", status) => {
  const url = `/v1/qbeek/merchant/update`;
  try {
    const response = await axiosInstance.post(url, {
      statusOfSubscription: status,
    });
    return response;
  } catch (error) {
    console.error("Error fetching collection:", error.message);
  }
};

const urlIp2C = ref("https://ip2c.org/s");
export async function getMerchantCurrencySign(target) {
  try {
    const url = `/qbeek/merchant/read`;
    const merchantData = await axiosInstance.get(url);
    // console.log("merchantData", JSON.stringify(merchantData));

    if (merchantData?.currencySign) {
      target.value = merchantData?.currencySign;
    } else {
      const { error, data } = await useFetch(urlIp2C);
      if (!error.value) {
        const country = data.value.split(";")[2];
        const { currencycode } = countryCurrency.find(
          (el) => el.country === country
        );
        target.value = currency.find(
          (el) => el.currencyNumCode == currencycode
        );
        await updateMerchantCurrencySign(target.value);
      }
    }
    // }
  } catch (error) {
    console.log(error);
  }
}
export async function updateMerchantCurrencySign(name) {
  const url = `/v1/qbeek/merchant/update`;
  try {
    const response = await axiosInstance.post(url, {
      currencySign: name,
    });
  } catch (error) {
    console.log(error);
  }
}
export async function checkNameUniq(slugName) {
  const url = `/v1/qbeek/merchant/slug-name-is-uniq`;
  try {
    const response = await axiosInstance.post(url, { slugName });
    return !response;
  } catch (error) {
    console.log("checkNameUniq: => ", error);
  }
}
export async function getMerchantSlugName(merchantSlugName) {
  const url = `/qbeek/merchant/read`;

  try {
    const response = await axiosInstance.get(url);
    merchantSlugName.value = response?.merchantSlugName;
  } catch (error) {
    console.log(error);
  }
}
export async function updateMerchantSlugName(name) {
  try {
    const url = `/v1/qbeek/merchant/update`;
    const response = await axiosInstance.post(url, { merchantSlugName: name });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/* Merchant */
export async function getMerchant() {
  const url = `/qbeek/merchant/read`;
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.log("Error fetching collection:getMerchant", error);
    return;
  }
}
export async function getMerchantOne() {
  const url = `/qbeek/merchant/read`;
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.log("Error fetching collection:getMerchantOne", error);
  }
}
/* MerchantId */
export async function getMerchantId() {
  try {
    const { merchantId } = await getMerchant();
    return merchantId;
  } catch (error) {
    console.log("Error fetching collection:getMerchantId", error);
  }
}

/* ===Staff=== */

/* Get Staff */
export async function getMerchantStaff() {
  try {
    const { staff } = await getMerchant();
    return staff;
  } catch (error) {
    console.log("Error fetching collection:getMerchantStaff", error);
  }
}
/* Add Staff */
export async function saveMerchantEmployee(employee) {
  const url = `/v1/qbeek/merchant/update`;
  const urlRead = `/qbeek/merchant/read`;
  try {
    const merchant = await axiosInstance.get(urlRead);
    if (!merchant?.staff) {
      merchant.staff = [];
    }
    const response = await axiosInstance.post(url, {
      staff: [...merchant?.staff, employee],
    });
    console.log("Сотрудник успешно сохранен!");
    return response;
  } catch (error) {
    console.log("Error fetching collection:saveMerchantEmployee", error);
  }
  //   console.log("Сотрудник успешно сохранен!");
}
export async function checkIfDomainAlreadyExists(target, subdomain) {
  try {
    const pointsBySubdomain = (await getPointsData()).filter(
      (point) => point.subdomain === subdomain
    );

    if (pointsBySubdomain.length) {
      target.value = true;
      return true;
    }
    target.value = false;
    return false;
  } catch (error) {
    console.log("Error fetching collection:checkIfDomainAlreadyExists", error);
  }
}
