import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "points",
    name: "points",

    component: load("points/ViewPoints"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
      icon: "phone",
      menuItems: "leftSideMenu.pointsOfSale",
    },
    redirect: {
      name: "points:list",
    },
    children: [
      {
        path: "points-list",
        name: "points:list",

        component: load("points/Points"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
          icon: "phone",
          menuItems: "leftSideMenu.pointsOfSale",
        },
        children: [],
      },
      {
        path: "points-work-time",
        name: "points:workTime",

        component: load("points/workTime/PointsWorkTime"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
          icon: "phone",
          menuItems: "leftSideMenu.pointsOfSale",
        },
        children: [],
      },
      {
        path: "points-departments",
        name: "points:departments",

        component: load("points/departments/PointsDepartments"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
          icon: "phone",
          menuItems: "leftSideMenu.pointsOfSale",
        },
        children: [],
      },
    ],
  },
  {
    path: "points/new-point",
    name: "new-point",

    component: load("points/EditPoint"),
    meta: {
      requiresAuth: true,
      icon: "phone",
      menuItems: "leftSideMenu.pointsOfSale",
    },
    children: [],
  },
  {
    path: "points/edit/:pointId",
    name: "edit-point",
    component: load("points/EditPoint"),
    props: true,
    meta: {
      requiresAuth: true,
      icon: "phone",
      menuItems: "leftSideMenu.pointsOfSale",
    },
    children: [],
  },
];
