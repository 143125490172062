<template>
  <vue-final-modal
    class="guide-redirect"
    overlay-class="guide-redirect__overlay"
    content-class="guide-redirect__content"
    :click-to-close="false"
  >
    <div class="warning-screen">
      <div class="content">
        <div class="header">
          <slot name="header" />
        </div>
        <div class="desc">
          <slot name="desc" />
        </div>
      </div>
      <div class="buttons">
        <action-button action class="add" @click="closeModal">
          <span class="btn-text">Продолжить</span>
        </action-button>
      </div>
      <v-icon
        class="x-close"
        name="close"
        stroke="#0E0E22"
        @click="closeModal"
      />
    </div>
  </vue-final-modal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";

// eslint-disable-next-line no-undef
const emit = defineEmits({
  'update:modelValue': null,
});

function closeModal() {
  emit('update:modelValue', false);
}
</script>

<style lang="scss" scoped>
.warning-screen,
.warning-screen * {
  box-sizing: border-box;
}

.warning-screen {
  background: #ffffff;
  border-radius: 20px;
  padding: 32px 24px;
  width: 100%;
  max-width: 480px;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 24px;
}

.header {
  color: var(--maincolors-header, #0e0e22);
  text-align: center;
  font: var(--dashboard-h-1, 600 20px/110% "Montserrat", sans-serif);
  position: relative;
  align-self: stretch;
}

.desc {
  color: var(--maincolors-text, #0e0e22);
  text-align: center;
  font: var(--dashboard-textmd, 400 14px/130% "Inter", sans-serif);
  position: relative;
  align-self: stretch;

  p {
    margin: 0;
    padding: 12px 0px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
}

.x-close {
  flex-shrink: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  overflow: visible;
  cursor: pointer;
}

.add {
  width: 160px;
}

.btn-text {
  color: var(--maincolors-white, #ffffff);
  text-align: left;
  font: var(--dashboard-buttonmd, 500 16px/130% "Inter", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
