import { ref } from "vue";
import CreateNetwork from "@/views/points/modals/CreateNetwork.vue";
import { useModal } from "vue-final-modal";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";

export function useCreateNetwork() {
  const merchantSlugName = ref("");
  merchantSlugName.value = store.getters.merchantSlugName;

  const createNetworkModal = useModal({
    component: CreateNetwork,
    attrs: {
      title: "Create Network",
      clickToClose: false,
      escToClose: false,
      onConfirm() {
        createNetworkModal.close();
        router.push({
          name: "new-point",
          params: { locale: i18n.global.locale },
        });
      },
      onClose() {
        router.push({
          name: "catalog:settings",
          params: { locale: i18n.global.locale },
        });
        createNetworkModal.close();
      },
    },
  });
  async function goToNewPoint() {
    merchantSlugName.value = store.getters.merchantSlugName;
    if (!merchantSlugName.value) {
      createNetworkModal.open();
    } else {
      router.push({
        name: "new-point",
        params: { locale: i18n.global.locale },
      });
    }
  }
  return { goToNewPoint };
}
