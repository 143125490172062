<template>
  <div id="nav"
class="navbar">
    <span class="menuitem">
      {{ activeMenuItem }}
    </span>
    <div class="links">
      <!-- v-if="isNotEmployee() && clubcardEnabled" -->
      <action-button v-if="false"
action
variant1
@click="open">
        Пополнить депозит клиента
      </action-button>
      <dropdown-menu :items="menuItems">
        <template #icon>
          <username-badge :first-last="firstLast" />
        </template>
        <template #role>
          {{ role.label }}
        </template>
        <template #firstLast>
          {{ fl }}
        </template>
        <!-- <template #email>
          {{ user.email }}
        </template> -->
      </dropdown-menu>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import UsernameBadge from "./UsernameBadge.vue";
import DropdownMenu from "@/components/UI/dropdownMenu/DropdownMenu.vue";
import TopUpAccount from "@/components/UI/modals/TopUpAccount.vue";
import { getUserState } from "@/firebase";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useModal } from "vue-final-modal";
import i18n from "@/i18n";
import { AccessPermissionsEnum } from "@/router/constants";
import { useAuthService } from "@/modules/auth/auth.service";
import { getUserById } from "@/services/user.service";

const route = useRoute();
const store = useStore();
const { checkHasPermission } = useAuthService();
const firstName = ref(" ");
const lastName = ref(" ");
const role = ref(" ");
const user = ref({});
const firstLast = computed(() => {
  if (firstName?.value === "?") {
    return "?";
  }
  return firstName?.value[0]?.toUpperCase() + lastName?.value[0]?.toUpperCase();
});
const fl = computed(() => {
  if (firstName?.value === "?") {
    return "No data";
  }
  return `${firstName?.value} ${lastName?.value}`;
});
const items = ref([
  {
    title: "nav.account",
    name: "account",
    path: "account",
    icon: "user",
    accessPermissions: [AccessPermissionsEnum.CAN_READ_SUBSCRIPTION],
  },
  {
    title: "main.signout",
    name: "login",
    path: "login",
    addClick: true,
    icon: "signout",
  },
]);
const menuItems = computed(() =>
  items.value.filter(
    (it) =>
      !Object.hasOwn(it, "accessPermissions") ||
      checkHasPermission(it.accessPermissions)
  )
);
const activeMenuItem = computed(() =>
  i18n.global.t(store.getters.get_menuitem || "_")
);

const { open, close } = useModal({
  component: TopUpAccount,
  attrs: {
    onConfirm() {
      close();
    },
    onClose() {
      close();
    },
  },
  slots: {
    default: "<p>The content of the modal</p>",
  },
});
onMounted(async () => {
  try {
    const isAuth = await getUserState();
    user.value = await getUserById(isAuth.uid);
    if (isAuth !== null) {
      if (firstName.value) {
        firstName.value = user.value.firstName || user.value.firstName || "?";
        lastName.value = user.value.lastName || user.value.lastName || "";
      } else {
        firstName.value = user.value.firstName || user.value.firstName || "?";
        lastName.value = user.value.lastName || user.value.lastName || "";
      }
      firstName.value = user.value.firstName || user.value.firstName || "?";
      lastName.value = user.value.lastName || user.value.lastName || "";
      role.value = user.value.role || "no data";
    }
    if (route.meta.menuItems)
      store.commit("SET_MENUITEM", route.meta.menuItems);
  } catch (error) {
    console.log(error);
  }
});
</script>

<style lang="scss" scoped>
.navbar {
  background: #fff !important;
  z-index: 10;
}

#nav {
  width: 100%;
  height: 64px;
  left: 0px;
  top: 0px;
  background: #f5f6f8;
  display: flex;
  justify-content: space-between;
  position: sticky;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .logo {
    width: 260px;
    min-width: 260px;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .links {
    padding: 12px 28px 12px 0px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * + * {
      margin-left: 20px;
    }
  }
}
#nav .logo.mobile {
  width: 85px !important;
  min-width: 85px !important;
  &:hover {
    width: 260px;
    min-width: 260px;
  }
  transition: all 0.3s ease;
}
.menuitem {
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 32px;
  color: #0e0e22;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  gap: 30px;
}
.temp-info {
  width: 100%;
  text-align: center;
  display: flex;
  gap: 30px;
}
</style>
