import { ref, computed } from 'vue'
import { useStorage, useTimeoutFn } from '@vueuse/core'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_SERVER_AUTH_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
export function useAuth() {
  const accessToken = useStorage('accessToken', '')
  const refreshToken = useStorage('refreshToken', '')
  const merchantId = useStorage('merchantId', '')
  const merchants = useStorage('merchants', [])
  const user = useStorage('user', null)
  const uid = useStorage('uid', null)
  const role = useStorage('role', null)
  const isAuthenticated = computed(() => !!accessToken.value)
  const isLoading = ref(false)
  const error = ref(null)

  // Set up auto refresh before token expires
  const setupAutoRefresh = (expiresAt) => {
    const timeoutMs = (expiresAt) - Date.now() - (60 * 1000) // Refresh 1 minute before expiry
    // console.log('setupAutoRefresh:expiresAt', timeoutMs);

    if (timeoutMs > 0) {
      useTimeoutFn(async () => {
        await refreshAccessToken()
      }, timeoutMs)
    }
  }

  const login = async ({ email, password }, isRememberMe = false) => {
    try {
      isLoading.value = true
      error.value = null

      const response = await api.post('/api/login', {
        email,
        password
      })

      refreshToken.value = response.data.refreshToken
      merchants.value = response.data.merchants

      // If merchants exist, set the first one as default
      if (merchants.value.length > 0) {
        merchantId.value = merchants.value[0].id
        // Get initial access token using refresh token and merchant ID
        await refreshAccessToken();
        await validateToken();
      }

      return true
    } catch (err) {
      error.value = err.response?.data?.message || 'Login failed'
      throw error.value
    } finally {
      isLoading.value = false
    }
  }

  const refreshAccessToken = async () => {
    try {
      console.group("refreshAccessToken");
      if (!refreshToken.value || !merchantId.value) return false
      // console.log('refreshToken.value:', refreshToken.value);
      // console.log('merchantId.value:', merchantId.value);

      const response = await api.post('/api/token/refresh', {
        refreshToken: refreshToken.value,
        merchantId: merchantId.value
      })
      // console.log('response:', response.data);

      accessToken.value = response.data.accessToken
      setupAutoRefresh(response.data.expiresAt)
      return true
    } catch (err) {
      // If refresh fails, log out the user
      console.error('Error refreshing token:', err)
      console.log('logout()', err);
      // await logout()
      error.value = 'Session expired. Please login again.'
      throw error.value
    } finally {
      console.groupEnd();
    }
  }

  const validateToken = async () => {
    try {
      if (!accessToken.value) return false

      const response = await api.post('/api/token/validate', {
        accessToken: accessToken.value
      })

      if (response.data === false) {
        return false
      }
      user.value = JSON.stringify(response.data)
      uid.value = response.data.userId
      role.value = response.data.role
      return true
    } catch {
      return false
    }
  }

  const logout = async () => {
    try {
      if (accessToken.value) {
        await api.post('/api/token/revoke', {
          accessToken: accessToken.value
        })
      }
    } catch (err) {
      console.error('Error revoking token:', err)
    } finally {
      accessToken.value = ''
      refreshToken.value = ''
      merchantId.value = ''
      merchants.value = []
      user.value = null
      uid.value = ''
      role.value = ''
    }
  }

  const switchMerchant = async (newMerchantId) => {
    try {
      merchantId.value = newMerchantId
      await refreshAccessToken()
      return true
    } catch (err) {
      error.value = 'Failed to switch merchant'
      throw error.value
    }
  }

  return {
    accessToken,
    merchantId,
    merchants,
    user,
    isAuthenticated,
    isLoading,
    error,
    login,
    logout,
    validateToken,
    refreshAccessToken,
    switchMerchant
  }
}