import { computed } from "vue";
import { db, getUserState, storage } from "@/firebase";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import debounce from "lodash.debounce";

import store from "@/store";
import axiosInstance from "./axios.service";
import { useStorage } from "@vueuse/core";

const merchant = computed(() => store.getters.merchant);
const uid = useStorage("uid", null);

/* ========================================= */

const userMap = (user) => ({
  id: user.id,
  name: `${user.firstName || user.firstname} ${user.lastName || user.lastname}`,
  phone: user.phones ? user.phones[0].number : "",
});
/* ========================================= */
export async function getUserRole() {
  // const user = await getUserState();
  const userInStorage = localStorage.getItem("user") || sessionStorage.getItem("user");
  const user = typeof userInStorage === "string" && JSON.parse(userInStorage);
  // console.log('user:getUserRole', user);

  return user?.role;
}
export async function getUserPermissionsByRoles(role, headers = {}) {
  const url = `/operator/permissions?role=${role}`;
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error("Error posting data=createPoint=>:", error);
    return { status: 400, message: error };
  }
}
export async function getUserRoleAndHash() {
  const user = await getUserState();
  const dbUser = await getUserById(user.uid);
  return { role: dbUser?.role?.value, hash: dbUser?.hash };
}
/**
 * This asynchronous function retrieves a user from a Firestore database.
 * It only returns the fields specified in the input parameter.
 *
 * @param {Array} fields - An array of strings representing the fields to be retrieved for the user.
 * @returns {Object|undefined} - An object containing the requested fields of the user, or undefined if no such fields exist.
 */
export async function getUser(fields) {
  const u = {};
  const { uid } = await getUserState();
  // console.log("uid", uid);
  try {
    const dbUser = await getUserById(uid);
    if (!dbUser) return;
    for (const key in dbUser) {
      const element = dbUser[key];
      if (fields.includes(key)) {
        u[key] = element;
      }
    }
    return Object.keys(u).length ? u : undefined;
  } catch (error) {
    console.error("Error posting data=getUser=>:", error);
    return { status: 400, message: error };
  }
}
export async function getUserById(userId) {
  const url = `/operator/personal/read/${userId}`;
  // const auth = getAuth();
  // const token = await auth.currentUser.getIdToken();
  // console.log("token", token);

  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error("Error posting data=getUserById=>:", error);
    return { status: 400, message: error };
  }
}
export async function setUserField(field, value) {
  const { uid } = await getUserState();
  const url = `/operator/personal/update/${uid}`;
  const data = {
    [field]: value,
  };
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    console.error("Error posting data=setUserField=>:", error);
    return { status: 400, message: error };
  }
}

export const isEmailUnique = debounce(async (email) => {
  if (!email) return Promise.resolve(false);
  const url = `/operator/personal/is-email-used
`;
  try {
    const isEmailUnique = await axiosInstance.post(url, { email });
    return new Promise((resolve) => {
      resolve(!isEmailUnique);
    });

  } catch (error) {
    return new Promise((resolve) => {
      resolve(false);
    });
  }
}, 500);

export const checkNameUniq = debounce(async (slugName) => {
  const url = `/operator/merchant/slug-name-is-uniq`;
  try {
    return axiosInstance.post(url, { slugName });
  } catch (error) {
    return new Promise((resolve) => {
      resolve(false);
    });
  }
}, 800);
export const isPhoneUnique = debounce(async (phone /* , pointId */) => {
  let result = [];
  let arr = [];
  const url = `/operator/personal/list`;
  try {
    const allUserByMerchant = await axiosInstance.get(url);
    allUserByMerchant.forEach((doc) => {
      arr.push(doc);
    });
    arr.forEach((user) => {
      if ("point" in user) {
        if (Array.isArray(user.point) && user.point.length > 0) {
          user.point.forEach((p) => {
            result.push(...user.phones.map((ph) => ph.number));
          });
        } else if (typeof user.point === "object") {
          result.push(...user.phones.map((ph) => ph.number));
        }
      }
    });
    // console.log("result:isPhoneUnique", result);
    // console.log("phone:isPhoneUnique", phone);
    // return !result.includes(phone);
    return !result.includes(phone);
  } catch (error) {
    return new Promise((resolve) => {
      resolve(false);
    });
  }


}, 800);
// TODO: Deprecate
export function firstIsUniqueSku(pointId, isEdit = false, updSku = null) {
  return async function (sku) {
    let result;
    if (isEdit) {
      if (updSku.value === sku) {
        return true;
      }
      const url = `/operator/goods/check-unique-sku`;
      result = await axiosInstance.post(url, {
        pointId,
        sku,
      });
    } else {
      const url = `/operator/goods/check-unique-sku`;
      result = await axiosInstance.post(url, {
        pointId,
        sku,
      });
    }
    return result;
  };
}
export async function getUsers(userIdList = []) {
  // const { merchantId } = merchant.value;

  if (userIdList.length === 0) return [];
  const users = [];
  const url = `/operator/personal/list`;
  const querySnapshot = await axiosInstance.get(url);
  querySnapshot.forEach((doc) => {
    if (userIdList.includes(doc.id)) users.push(userMap(doc));
  });
  // console.log("querySnapshot", querySnapshot);
  // console.log("users", users);

  return users;
}

export async function getUserImgUrl(userId) {
  const fileRef = storageRef(storage, `users/${userId}/img/avatar.jpg`);
  let imgUrl = "";
  try {
    imgUrl = await getDownloadURL(fileRef);
  } catch (error) {
    console.log("error", error);
  }
  return imgUrl;
}
export async function setUserDisabled(uid) {
  const url = `/operator/personal/delete/${uid}`;
  const response = await axiosInstance.delete(url);
  // const response = await setUserField("disabled", true);
  return response;
}
export async function updateUserData(data) {
  const { uid } = await getUserState();
  const url = `/operator/personal/update/${uid}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    console.error("Error posting data=updateUserData=>:", error);
    return { status: 400, message: error };
  }
}
export async function updateUserDataById(uid, data) {
  const url = `/operator/personal/update/${uid}`;
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    console.error("Error posting data=updateUserData=>:", error);
    return { status: 400, message: error };
  }
}
