// services/apiService.js
import axios from "axios";

import { getAuth } from "firebase/auth";

const auth = getAuth();

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL, //"http://13.229.219.131:7000",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    return response.data;
  },
  (error) => {
    let errorMessage = "An error occurred"; // Сообщение по умолчанию
    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorMessage = "Error 404: Resource not found";
          break;
        case 400:
          errorMessage = "Error 400: Bad Request";
          break;
        // Можно добавить обработку других статусов
        default:
          errorMessage = "Unexpected error occurred";
      }
    } else {
      errorMessage = error.message || "Network error";
    }
    return Promise.reject(errorMessage);
  }
);

// Function to make a POST request
export const getMenuItems = async (data, headers = {}) => {
  const url = "/menu-items";
  headers["target"] = "test-pos";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log("response:getMenuItems", response);
    const result = response.items;
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // throw error;
  }
};

// Export axios instance with pre-configured interceptors
export default axiosInstance;
