<template>
  <svg width="32"
height="32"
viewBox="0 0 32 32"
fill="none"
xmlns="http://www.w3.org/2000/svg">
    <path d="M6 17.449V26.0005C6 26.2657 6.10536 26.5201 6.29289 26.7076C6.48043 26.8952 6.73478 27.0005 7 27.0005H25C25.2652 27.0005 25.5196 26.8952 25.7071 26.7076C25.8946 26.5201 26 26.2657 26 26.0005V17.4492"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M6.7543 5.00049H25.2457C25.463 5.00049 25.6744 5.07128 25.8479 5.20214C26.0214 5.333 26.1475 5.51682 26.2072 5.72577L28 12.0005H4L5.79278 5.72577C5.85248 5.51682 5.97862 5.333 6.15211 5.20214C6.3256 5.07128 6.53699 5.00049 6.7543 5.00049Z"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M12 12.0005V14.0005C12 15.0614 11.5786 16.0788 10.8284 16.8289C10.0783 17.5791 9.06087 18.0005 8 18.0005C6.93913 18.0005 5.92172 17.5791 5.17157 16.8289C4.42143 16.0788 4 15.0614 4 14.0005V12.0005"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M20 12.0005V14.0005C20 15.0614 19.5786 16.0788 18.8284 16.8289C18.0783 17.5791 17.0609 18.0005 16 18.0005C14.9391 18.0005 13.9217 17.5791 13.1716 16.8289C12.4214 16.0788 12 15.0614 12 14.0005V12.0005"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M28 12.0005V14.0005C28 15.0614 27.5786 16.0788 26.8284 16.8289C26.0783 17.5791 25.0609 18.0005 24 18.0005C22.9391 18.0005 21.9217 17.5791 21.1716 16.8289C20.4214 16.0788 20 15.0614 20 14.0005V12.0005"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
  </svg>
</template>