export default {
  "login.errors.wrongLoginOrPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])};fn.source="Invalid username or password";return fn;})(),
  "profile": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])};fn.source="Profile";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile setup"])};fn.source="Profile setup";return fn;})(),
    "firstname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])};fn.source="Firstname";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])};fn.source="John";return fn;})()
    },
    "lastname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])};fn.source="Lastname";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])};fn.source="Doe";return fn;})()
    },
    "patronymic": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronymic"])};fn.source="Patronymic";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])};fn.source="...";return fn;})()
    },
    "email": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["john.doe", "@", "gmail.com"])};fn.source="john.doe{'@'}gmail.com";return fn;})()
    },
    "phone": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])};fn.source="Phone";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])};fn.source="Phone";return fn;})()
    },
    "buttons": {
      "changePasswords": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])};fn.source="Change password";return fn;})(),
      "bankDetails": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])};fn.source="Bank details";return fn;})(),
      "tipCardNumber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip card number"])};fn.source="Tip card number";return fn;})(),
      "saveUserInfo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user data"])};fn.source="Update user data";return fn;})()
    },
    "passwordChange": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])};fn.source="Change Password";return fn;})(),
      "form": {
        "oldPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])};fn.source="Current password";return fn;})(),
        "newPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])};fn.source="New password";return fn;})(),
        "confirmNewPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New password"])};fn.source="Confirm New password";return fn;})()
      },
      "buttons": {
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])};fn.source="Change Password";return fn;})()
      },
      "errors": {
        "require": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot be empty"])};fn.source="Password cannot be empty";return fn;})(),
        "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password must contain at least ", _interpolate(_named("n")), " characters"])};fn.source="Password must contain at least {n} characters";return fn;})(),
        "samePassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])};fn.source="Passwords must match";return fn;})(),
        "hasDigitMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one number"])};fn.source="Passwor must contain at least one number";return fn;})(),
        "hasUppercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one uppercase letter"])};fn.source="Passwor must contain at least one uppercase letter";return fn;})(),
        "hasLowercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one  one special character"])};fn.source="Passwor must contain at least one  one special character";return fn;})(),
        "hasSpecialSymbolsMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasSpecialSymbolsMessage"])};fn.source="hasSpecialSymbolsMessage";return fn;})(),
        "tooManyRequests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests"])};fn.source="Too many requests";return fn;})(),
        "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong current password"])};fn.source="Wrong current password";return fn;})()
      }
    },
    "bankDetails": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])};fn.source="Bank Details";return fn;})(),
      "address": {
        "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])};fn.source="Choose a point";return fn;})(),
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])};fn.source="Choose a point";return fn;})()
      },
      "buttons": {
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})()
      }
    }
  },
  "datapicker": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "timeplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time"])};fn.source="Select Time";return fn;})(),
    "dateplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])};fn.source="Select Date";return fn;})()
  },
  "tasks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Settings"])};fn.source="Task Settings";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a task for a specific time"])};fn.source="Setting up a task for a specific time";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])};fn.source="Date and Time";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the task"])};fn.source="Here you can set the date and time of the task";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task name cannot be empty"])};fn.source="Task name cannot be empty";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task name cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="Task name cannot be less than {n} characters";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task description cannot be empty"])};fn.source="Task description cannot be empty";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task description cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="Task description cannot be less than {n} characters";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])};fn.source="Select at least one point";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])};fn.source="Date cannot be after end date";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])};fn.source="Date cannot be before start date";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit task"])};fn.source="Edit task";return fn;})()
    }
  },
  "news": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Settings"])};fn.source="News Settings";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a news for a specific time"])};fn.source="Setting up a news for a specific time";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])};fn.source="Date and Time";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the news"])};fn.source="Here you can set the date and time of the news";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News name cannot be empty"])};fn.source="News name cannot be empty";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["News name cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="News name cannot be less than {n} characters";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News description cannot be empty"])};fn.source="News description cannot be empty";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["News description cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="News description cannot be less than {n} characters";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])};fn.source="Select at least one point";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])};fn.source="Date cannot be after end date";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])};fn.source="Date cannot be before start date";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing news"])};fn.source="Editing news";return fn;})()
    }
  },
  "feedbacks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Settings"])};fn.source="Feedback Settings";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a feedback for a specific time"])};fn.source="Setting up a feedback for a specific time";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])};fn.source="Date and Time";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the feedback"])};fn.source="Here you can set the date and time of the feedback";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback name cannot be empty"])};fn.source="Feedback name cannot be empty";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feedback name cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="Feedback name cannot be less than {n} characters";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback description cannot be empty"])};fn.source="Feedback description cannot be empty";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feedback description cannot be less than ", _interpolate(_named("n")), " characters"])};fn.source="Feedback description cannot be less than {n} characters";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])};fn.source="Select at least one point";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])};fn.source="Date cannot be after end date";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])};fn.source="Date cannot be before start date";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing feedback"])};fn.source="Editing feedback";return fn;})()
    }
  },
  "multiselect": {
    "current": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 points selected"]), _normalize([_interpolate(_named("n")), " point selected"]), _normalize([_interpolate(_named("n")), " points selected"]), _normalize([_interpolate(_named("n")), " points selected"])])};fn.source="0 points selected | {n} point selected | {n} points selected | {n} points selected";return fn;})(),
    "allpoints": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All points selected"])};fn.source="All points selected";return fn;})()
  },
  "phone": {
    "countrySelector": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])};fn.source="Country code";return fn;})(),
      "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])};fn.source="Choose country";return fn;})()
    },
    "phoneInput": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])};fn.source="Phone number";return fn;})(),
      "example": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example:"])};fn.source="Example:";return fn;})()
    }
  },
  "button": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
    "update": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])};fn.source="Update";return fn;})()
  },
  "toast": {
    "successSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " settings saved!"])};fn.source="{target} settings saved!";return fn;})(),
    "errorSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error saving ", _interpolate(_named("target")), " settings!"])};fn.source="Error saving {target} settings!";return fn;})(),
    "deleted": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " deleted!"])};fn.source="{target} deleted!";return fn;})(),
    "word": {
      "product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])};fn.source="Product";return fn;})()
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of clients"])};fn.source="List of clients";return fn;})()
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клиентов"])};fn.source="Список клиентов";return fn;})()
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])};fn.source="Clients";return fn;})(),
      "push": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push"])};fn.source="Push";return fn;})(),
      "feedbacks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedbacks"])};fn.source="Feedbacks";return fn;})(),
      "templates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])};fn.source="Templates";return fn;})(),
      "triggers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic mailing"])};fn.source="Automatic mailing";return fn;})(),
      "orders": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])};fn.source="Orders";return fn;})(),
      "kitchen": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen"])};fn.source="Kitchen";return fn;})()
    },
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])};fn.source="Feedback";return fn;})(),
    "notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])};fn.source="Notifications";return fn;})()
  },
  "staff": {
    "buttonTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff setting"])};fn.source="Staff setting";return fn;})(),
    "buttonCalls": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calling staff"])};fn.source="Calling staff";return fn;})(),
    "buttonTasks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])};fn.source="Tasks";return fn;})(),
    "buttonNews": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News feed"])};fn.source="News feed";return fn;})(),
    "buttonNotifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])};fn.source="Notifications";return fn;})(),
    "buttonFeedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])};fn.source="Feedback";return fn;})(),
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New employee"])};fn.source="New employee";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new employee"])};fn.source="Add new employee";return fn;})()
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit employee"])};fn.source="Edit employee";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make edits"])};fn.source="Make edits";return fn;})()
    },
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong login"])};fn.source="Wrong login";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])};fn.source="Email not found";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not be empty"])};fn.source="Email must not be empty";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid"])};fn.source="Email not valid";return fn;})(),
      "surnameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname must not be empty"])};fn.source="Surname must not be empty";return fn;})(),
      "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must not be empty"])};fn.source="Name must not be empty";return fn;})(),
      "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field must contain at least 3 characters"])};fn.source="Field must contain at least 3 characters";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])};fn.source="Wrong password";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])};fn.source="Wrong password";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not be empty"])};fn.source="Password must not be empty";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find a user with this email address."])};fn.source="We cannot find a user with this email address.";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone cannot be empty"])};fn.source="Phone cannot be empty";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])};fn.source="Phone length check";return fn;})()
      }
    }
  },
  "pselection": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a loyalty program"])};fn.source="Choosing a loyalty program";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сhoose and customize your loyalty program"])};fn.source="Сhoose and customize your loyalty program";return fn;})(),
    "radioButton": {
      "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])};fn.source="Discount";return fn;})(),
      "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])};fn.source="Bonus";return fn;})()
    },
    "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])};fn.source="Next";return fn;})(),
    "discount": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount loyalty program"])};fn.source="Discount loyalty program";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up your direct discount to the customer"])};fn.source="Setting up your direct discount to the customer";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting levels"])};fn.source="Setting levels";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the required number of levels, their sum and percentage of the discount"])};fn.source="Select the required number of levels, their sum and percentage of the discount";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])};fn.source="Level name";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum from"])};fn.source="Sum from";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])};fn.source="Up to";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount, %"])};fn.source="Discount, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])};fn.source="Add level";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])};fn.source="Discount";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cardowner"])};fn.source="cardowner";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "untilDiscount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until discount"])};fn.source="until discount";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])};fn.source="Name Surname";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount settings saved!"])};fn.source="Discount settings saved!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Discount settings!"])};fn.source="Error saving Discount settings!";return fn;})()
      }
    },
    "bonus": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus loyalty system"])};fn.source="Bonus loyalty system";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize for your bonus program"])};fn.source="Customize for your bonus program";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting levels"])};fn.source="Setting levels";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the required number of levels, their sum and percentage of the discount"])};fn.source="Select the required number of levels, their sum and percentage of the discount";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])};fn.source="Level name";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum from"])};fn.source="Sum from";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])};fn.source="Up to";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus, %"])};fn.source="Bonus, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])};fn.source="Add level";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])};fn.source="Discount";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balans"])};fn.source="Balans";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cardowner"])};fn.source="cardowner";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until bonus"])};fn.source="until bonus";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])};fn.source="Name Surname";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus settings saved!"])};fn.source="Bonus settings saved!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Bonus settings!"])};fn.source="Error saving Bonus settings!";return fn;})()
      }
    },
    "coupon": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon loyalty program"])};fn.source="Coupon loyalty program";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The loyalty system allows creating one-time discount coupons (gift certificates) with different face values."])};fn.source="The loyalty system allows creating one-time discount coupons (gift certificates) with different face values.";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])};fn.source="Discount";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner"])};fn.source="Card owner";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "untilDiscount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until discount"])};fn.source="Until discount";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])};fn.source="Fullname";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])};fn.source="coupon";return fn;})(),
        "accumulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accumulation"])};fn.source="accumulation";return fn;})(),
        "congratulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["congratulation"])};fn.source="congratulation";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Bonus settings!"])};fn.source="Error saving Bonus settings!";return fn;})()
      }
    },
    "clubcard": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Card"])};fn.source="Club Card";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview of the club card"])};fn.source="Preview of the club card";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])};fn.source="Discount";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balans"])};fn.source="Balans";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner"])};fn.source="Card owner";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until bonus"])};fn.source="Until bonus";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])};fn.source="Full name";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])};fn.source="Coupon";return fn;})()
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])};fn.source="Brand";return fn;})(),
      "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])};fn.source="Wallet";return fn;})(),
      "qr-catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])};fn.source="QR-catalog";return fn;})()
    },
    "qrCatalog": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])};fn.source="QR-catalog";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сustomize your QR-catalog"])};fn.source="Сustomize your QR-catalog";return fn;})(),
      "colors": {
        "primary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Color"])};fn.source="Text Color";return fn;})(),
        "secondary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title color"])};fn.source="Title color";return fn;})(),
        "relief-1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color"])};fn.source="Background color";return fn;})(),
        "relief-2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category color"])};fn.source="Category color";return fn;})(),
        "relief-7": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color price"])};fn.source="Text color price";return fn;})(),
        "relief-6": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color price"])};fn.source="Background color price";return fn;})()
      },
      "logoTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo for Catalog"])};fn.source="Logo for Catalog";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo text for catalog"])};fn.source="Logo text for catalog";return fn;})(),
      "downloadLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR Code"])};fn.source="Download QR Code";return fn;})(),
      "previewLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "previewLinkHint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes will be available after clicking on the button \"Publish\""])};fn.source="All changes will be available after clicking on the button \"Publish\"";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRCatalog settings saved!"])};fn.source="QRCatalog settings saved!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving QR-catalog settings!"])};fn.source="Error saving QR-catalog settings!";return fn;})()
      }
    },
    "wallet": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сard Wallet"])};fn.source="Сard Wallet";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сustomize your card WALLET"])};fn.source="Сustomize your card WALLET";return fn;})(),
      "cardColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card color"])};fn.source="Card color";return fn;})(),
      "textColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color"])};fn.source="Text color";return fn;})(),
      "titleColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title color"])};fn.source="Title color";return fn;})(),
      "logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo for Wallet"])};fn.source="Logo for Wallet";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo text for Wallet"])};fn.source="Logo text for Wallet";return fn;})(),
      "centralImage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сentral image for Wallet"])};fn.source="Сentral image for Wallet";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
      "preview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])};fn.source="Preview";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet settings saved!"])};fn.source="Wallet settings saved!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Wallet settings!"])};fn.source="Error saving Wallet settings!";return fn;})()
      }
    }
  },
  "leftSideMenu": {
    "agents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])};fn.source="Agents";return fn;})(),
    "averageСheck": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average check"])};fn.source="Average check";return fn;})(),
    "catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])};fn.source="QR-catalog";return fn;})(),
    "clientData": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client data"])};fn.source="Client data";return fn;})(),
    "crm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])};fn.source="CRM";return fn;})(),
    "dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])};fn.source="Dashboard";return fn;})(),
    "faq": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])};fn.source="FAQ";return fn;})(),
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])};fn.source="Feedback";return fn;})(),
    "for-client": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Clent"])};fn.source="For Clent";return fn;})(),
    "for-staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Staff"])};fn.source="For Staff";return fn;})(),
    "history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])};fn.source="History";return fn;})(),
    "integrations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])};fn.source="Integrations";return fn;})(),
    "levelRetention": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer retention"])};fn.source="Customer retention";return fn;})(),
    "levelSatisfaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction"])};fn.source="Customer satisfaction";return fn;})(),
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])};fn.source="Main";return fn;})(),
    "merchants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])};fn.source="Clients";return fn;})(),
    "pointsOfSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of sale"])};fn.source="Points of sale";return fn;})(),
    "profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])};fn.source="Profile";return fn;})(),
    "pselection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty program"])};fn.source="Loyalty program";return fn;})(),
    "staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])};fn.source="Staff";return fn;})(),
    "statistic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])};fn.source="Statistic";return fn;})(),
    "support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])};fn.source="Support";return fn;})(),
    "topSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top sales"])};fn.source="Top sales";return fn;})(),
    "transaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])};fn.source="Transactions";return fn;})(),
    "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сard Wallet"])};fn.source="Сard Wallet";return fn;})(),
    "departments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])};fn.source="Departments";return fn;})()
  },
  "application": {
    "imageForBothTheme": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])};fn.source="Use the downloaded image for both themes in the app";return fn;})()
  },
  "locale": {
    "uk": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])};fn.source="Укр";return fn;})(),
    "ru": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])};fn.source="Рус";return fn;})(),
    "en": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])};fn.source="Eng";return fn;})()
  },
  "login": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])};fn.source="Welcome";return fn;})(),
    "forgotQuery": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])};fn.source="Forgot your password?";return fn;})(),
      "sub": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the e-mail specified during registration. We will send you a link to reset your password."])};fn.source="Enter the e-mail specified during registration. We will send you a link to reset your password.";return fn;})(),
      "messageSuccessReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent a password recovery link to the specified Email."])};fn.source="We have sent a password recovery link to the specified Email.";return fn;})()
    },
    "pleaseSignIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign-in to your account"])};fn.source="Please sign-in to your account";return fn;})(),
    "buttonLogin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])};fn.source="Sign in";return fn;})(),
    "buttonReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])};fn.source="Reset password";return fn;})(),
    "rememberMe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])};fn.source="Remember Me";return fn;})(),
    "forgotPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])};fn.source="Forgot Password?";return fn;})(),
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong login"])};fn.source="Wrong login";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])};fn.source="Email not found";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not be empty"])};fn.source="Email must not be empty";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid"])};fn.source="Email not valid";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])};fn.source="Wrong password";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])};fn.source="Wrong password";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not be empty"])};fn.source="Password must not be empty";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find a user with this email address."])};fn.source="We cannot find a user with this email address.";return fn;})()
    }
  },
  "form": {
    "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])};fn.source="email";return fn;})(),
    "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])};fn.source="password";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])};fn.source="Log in";return fn;})(),
    "register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])};fn.source="registration";return fn;})()
  },
  "menu": {
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Page"])};fn.source="Main Page";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])};fn.source="Login";return fn;})(),
    "registration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])};fn.source="Sign up";return fn;})()
  },
  "main": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", want to start setting up?"])};fn.source="{name}, want to start setting up?";return fn;})(),
    "signout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go out"])};fn.source="Go out";return fn;})(),
    "new_message": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no message"]), _normalize(["one message"]), _normalize([_interpolate(_named("count")), " messages"])])};fn.source="no message | one message | {count} messages";return fn;})()
  },
  "nav": {
    "account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account"])};fn.source="Personal account";return fn;})(),
    "profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])};fn.source="Profile";return fn;})(),
    "loyalty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty program"])};fn.source="Loyalty program";return fn;})(),
    "bank-links": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Links"])};fn.source="Bank Links";return fn;})()
  },
  "points": {
    "workTime": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating mode"])};fn.source="Operating mode";return fn;})()
    },
    "departments": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])};fn.source="Departments";return fn;})()
    },
    "titlenameNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New point of sale"])};fn.source="New point of sale";return fn;})(),
    "subtitleNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new point of sale. After adding information, click \"Save\"."])};fn.source="Add a new point of sale. After adding information, click \"Save\".";return fn;})(),
    "titlenameEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit point"])};fn.source="Edit point";return fn;})(),
    "subtitleEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit point for customers to contact you"])};fn.source="Edit point for customers to contact you";return fn;})(),
    "phone": {
      "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete phone number"])};fn.source="Delete phone number";return fn;})(),
      "addPhone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add phone"])};fn.source="Add phone";return fn;})()
    },
    "btnNewPoint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add point of sale"])};fn.source="Add point of sale";return fn;})(),
    "table": {
      "header": {
        "point": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POINTS OF SALE"])};fn.source="POINTS OF SALE";return fn;})(),
        "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS"])};fn.source="ADDRESS";return fn;})(),
        "qrQatalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR CATALOG"])};fn.source="QR CATALOG";return fn;})(),
        "phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE"])};fn.source="PHONE";return fn;})(),
        "viber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])};fn.source="VIBER";return fn;})(),
        "whatsapp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])};fn.source="WHATSAPP";return fn;})(),
        "telegram": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])};fn.source="TELEGRAM";return fn;})(),
        "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTION"])};fn.source="ACTION";return fn;})()
      },
      "body": {
        "emptyMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any sale points yet."])};fn.source="You haven't added any sale points yet.";return fn;})()
      },
      "delete": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete сontact"])};fn.source="Delete сontact";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the сontact? All data will be deleted"])};fn.source="Are you sure you want to delete the сontact? All data will be deleted";return fn;})(),
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})()
      }
    },
    "address": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])};fn.source="Address";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing your address"])};fn.source="Start typing your address";return fn;})()
    },
    "title": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])};fn.source="Title";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the title"])};fn.source="Enter the title";return fn;})()
    },
    "subdomain": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name"])};fn.source="Domain name";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the domain name"])};fn.source="Enter the domain name";return fn;})()
    },
    "errors": {
      "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address cannot be empty"])};fn.source="Address cannot be empty";return fn;})(),
      "subdomain": {
        "required": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name cannot be empty"])};fn.source="Domain name cannot be empty";return fn;})(),
        "latin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only latin symbols are allowed"])};fn.source="Only latin symbols are allowed";return fn;})(),
        "exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name already exists"])};fn.source="Domain name already exists";return fn;})()
      },
      "domain_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact not saved. Such domain name already exists"])};fn.source="Contact not saved. Such domain name already exists";return fn;})(),
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title cannot be empty"])};fn.source="Title cannot be empty";return fn;})(),
      "title_symbols": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is in the wrong format"])};fn.source="The field is in the wrong format";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone cannot be empty"])};fn.source="Phone cannot be empty";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])};fn.source="Phone length check";return fn;})()
      }
    },
    "catalog": {
      "point": {
        "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])};fn.source="Choose a point";return fn;})(),
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])};fn.source="Point";return fn;})()
      },
      "copy": {
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy QR-catalog from previous point?"])};fn.source="Copy QR-catalog from previous point?";return fn;})(),
        "yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])};fn.source="Yes";return fn;})(),
        "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])};fn.source="No";return fn;})()
      }
    }
  },
  "catalog": {
    "sale_channels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale channels"])};fn.source="Sale channels";return fn;})(),
    "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products and services"])};fn.source="Products and services";return fn;})(),
    "qr_catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog appearance"])};fn.source="Catalog appearance";return fn;})(),
    "point": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])};fn.source="Point";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])};fn.source="Choose a point";return fn;})(),
      "not_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start working with the catalog, select a point"])};fn.source="Before you start working with the catalog, select a point";return fn;})(),
      "list_is_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start working with the catalog, create a point"])};fn.source="Before you start working with the catalog, create a point";return fn;})()
    }
  },
  "products": {
    "errors": {
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field cannot be empty"])};fn.source="Field cannot be empty";return fn;})()
    }
  },
  "_": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})()
}