export default {
  "login.errors.wrongLoginOrPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний логін або пароль"])};fn.source="Невірний логін або пароль";return fn;})(),
  "profile": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])};fn.source="Профіль";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування профілю"])};fn.source="Налаштування профілю";return fn;})(),
    "firstname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])};fn.source="Ім'я";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степан"])};fn.source="Степан";return fn;})()
    },
    "lastname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])};fn.source="Прізвище";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степаненко"])};fn.source="Степаненко";return fn;})()
    },
    "patronymic": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По-батькові"])};fn.source="По-батькові";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степонович"])};fn.source="Степонович";return fn;})()
    },
    "email": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])};fn.source="Електронна пошта";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stepan.stepanenko", "@", "gmail.com"])};fn.source="stepan.stepanenko{'@'}gmail.com";return fn;})()
    },
    "phone": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])};fn.source="Телефон";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])};fn.source="Телефон";return fn;})()
    },
    "buttons": {
      "changePasswords": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])};fn.source="Змінити пароль";return fn;})(),
      "bankDetails": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банківські реквізити"])};fn.source="Банківські реквізити";return fn;})(),
      "tipCardNumber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер картки для чайових"])};fn.source="Номер картки для чайових";return fn;})(),
      "saveUserInfo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити дані користувача"])};fn.source="Оновити дані користувача";return fn;})()
    },
    "passwordChange": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])};fn.source="Змінити пароль";return fn;})(),
      "form": {
        "oldPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний пароль"])};fn.source="Поточний пароль";return fn;})(),
        "newPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль"])};fn.source="Новий пароль";return fn;})(),
        "confirmNewPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити новий пароль"])};fn.source="Підтвердити новий пароль";return fn;})()
      },
      "buttons": {
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])};fn.source="Скасувати";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])};fn.source="Змінити пароль";return fn;})()
      },
      "errors": {
        "require": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не може бути порожнім"])};fn.source="Пароль не може бути порожнім";return fn;})(),
        "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні 8 символів"])};fn.source="Пароль повинен містити принаймні 8 символів";return fn;})(),
        "samePassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролі повинні збігатися"])};fn.source="Паролі повинні збігатися";return fn;})(),
        "hasDigitMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну цифру"])};fn.source="Пароль повинен містити принаймні одну цифру";return fn;})(),
        "hasUppercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну прописну букву"])};fn.source="Пароль повинен містити принаймні одну прописну букву";return fn;})(),
        "hasLowercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну строчну букву"])};fn.source="Пароль повинен містити принаймні одну строчну букву";return fn;})(),
        "hasSpecialSymbolsMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні один спеціальний символ"])};fn.source="Пароль повинен містити принаймні один спеціальний символ";return fn;})()
      }
    }
  },
  "datapicker": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])};fn.source="Скасувати";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігти"])};fn.source="Зберігти";return fn;})(),
    "timeplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть час"])};fn.source="Оберіть час";return fn;})(),
    "dateplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть дату"])};fn.source="Оберіть дату";return fn;})()
  },
  "tasks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])};fn.source="Додати";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування завдання"])};fn.source="Налаштування завдання";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування завдання на певний час"])};fn.source="Налаштування завдання на певний час";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])};fn.source="Дата та час";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете налаштувати дату та час завдання"])};fn.source="Тут ви можете налаштувати дату та час завдання";return fn;})()
      },
      "erorrs": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва завдання не може бути порожньою"])};fn.source="Назва завдання не може бути порожньою";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва завдання не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Назва завдання не може бути менше {n} символів";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис завдання не може бути порожнім"])};fn.source="Опис завдання не може бути порожнім";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис завдання не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Опис завдання не може бути менше {n} символів";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б одну точку"])};fn.source="Виберіть хоча б одну точку";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])};fn.source="Дата не може бути після кінцевої";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])};fn.source="Дата не може бути до початкової";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування завдання"])};fn.source="Редагування завдання";return fn;})()
    }
  },
  "news": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])};fn.source="Додати";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування новини"])};fn.source="Налаштування новини";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування новини на певний час"])};fn.source="Налаштування новини на певний час";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])};fn.source="Дата та час";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете налаштувати дату та час новини"])};fn.source="Тут ви можете налаштувати дату та час новини";return fn;})()
      },
      "erorrs": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва новини не може бути порожньою"])};fn.source="Назва новини не може бути порожньою";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва новини не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Назва новини не може бути менше {n} символів";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис новини не може бути порожнім"])};fn.source="Опис новини не може бути порожнім";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис новини не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Опис новини не може бути менше {n} символів";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б одну точку"])};fn.source="Виберіть хоча б одну точку";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])};fn.source="Дата не може бути після кінцевої";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])};fn.source="Дата не може бути до початкової";return fn;})(),
        "tooManyRequests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забагато запитів"])};fn.source="Забагато запитів";return fn;})(),
        "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний поточний пароль"])};fn.source="Невірний поточний пароль";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування новини"])};fn.source="Редагування новини";return fn;})()
    }
  },
  "feedbacks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])};fn.source="Додати";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування зворотного зв'язку"])};fn.source="Налаштування зворотного зв'язку";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування зворотного зв'язку на певний час"])};fn.source="Налаштування зворотного зв'язку на певний час";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])};fn.source="Дата та час";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете встановити дату та час зворотного зв'язку"])};fn.source="Тут ви можете встановити дату та час зворотного зв'язку";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва зворотного зв'язку не може бути порожньою"])};fn.source="Назва зворотного зв'язку не може бути порожньою";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва зворотного зв'язку не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Назва зворотного зв'язку не може бути менше {n} символів";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис зворотного зв'язку не може бути порожнім"])};fn.source="Опис зворотного зв'язку не може бути порожнім";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис зворотного зв'язку не може бути менше ", _interpolate(_named("n")), " символів"])};fn.source="Опис зворотного зв'язку не може бути менше {n} символів";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б один пункт"])};fn.source="Виберіть хоча б один пункт";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])};fn.source="Дата не може бути після кінцевої";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])};fn.source="Дата не може бути до початкової";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування зворотного зв'язку"])};fn.source="Редагування зворотного зв'язку";return fn;})()
    }
  },
  "multiselect": {
    "current": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вибрано 0 точок"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точку"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точки"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точок"])])};fn.source="Вибрано 0 точок | Вибрано {n} точку | Вибрано {n} точки | Вибрано {n} точок";return fn;})(),
    "allpoints": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрано всі точки"])};fn.source="Вибрано всі точки";return fn;})()
  },
  "phone": {
    "countrySelector": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код країни"])};fn.source="Код країни";return fn;})(),
      "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть країну"])};fn.source="Виберіть країну";return fn;})()
    },
    "phoneInput": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону"])};fn.source="Номер телефону";return fn;})(),
      "example": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приклад:"])};fn.source="Приклад:";return fn;})()
    }
  },
  "button": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])};fn.source="Скасувати";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігти"])};fn.source="Зберігти";return fn;})(),
    "update": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])};fn.source="Оновити";return fn;})()
  },
  "toast": {
    "successSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Налаштування ", _interpolate(_named("target")), " збережено!"])};fn.source="Налаштування {target} збережено!";return fn;})(),
    "errorSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Помилка збереження налаштувань ", _interpolate(_named("target")), "!"])};fn.source="Помилка збереження налаштувань {target}!";return fn;})(),
    "deleted": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " видалено!"])};fn.source="{target} видалено!";return fn;})(),
    "word": {
      "product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])};fn.source="Продукт";return fn;})()
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список агентів"])};fn.source="Список агентів";return fn;})()
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клієнтів"])};fn.source="Список клієнтів";return fn;})()
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнти"])};fn.source="Клієнти";return fn;})(),
      "push": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розсилка"])};fn.source="Розсилка";return fn;})(),
      "feedbacks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відгуки"])};fn.source="Відгуки";return fn;})(),
      "templates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])};fn.source="Шаблони";return fn;})(),
      "triggers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичне розсилання"])};fn.source="Автоматичне розсилання";return fn;})(),
      "orders": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовлення"])};fn.source="Замовлення";return fn;})(),
      "kitchen": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кухня"])};fn.source="Кухня";return fn;})()
    },
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])};fn.source="Зворотній зв'язок";return fn;})(),
    "notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення"])};fn.source="Сповіщення";return fn;})()
  },
  "staff": {
    "buttonTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування персоналу"])};fn.source="Налаштування персоналу";return fn;})(),
    "buttonCalls": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виклик персоналу"])};fn.source="Виклик персоналу";return fn;})(),
    "buttonTasks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдання"])};fn.source="Завдання";return fn;})(),
    "buttonNews": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новинна стрічка"])};fn.source="Новинна стрічка";return fn;})(),
    "buttonNotifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cповіщення"])};fn.source="Cповіщення";return fn;})(),
    "buttonFeedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])};fn.source="Зворотній зв'язок";return fn;})(),
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])};fn.source="Додати";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий співробітник"])};fn.source="Новий співробітник";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте нового співробітника"])};fn.source="Додайте нового співробітника";return fn;})()
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати співробітник"])};fn.source="Редагувати співробітник";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внести зміни"])};fn.source="Внести зміни";return fn;})()
    },
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний логін"])};fn.source="Неправильний логін";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не знайдений"])};fn.source="Email не знайдений";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не повинен бути порожнім"])};fn.source="Email не повинен бути порожнім";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний формат email"])};fn.source="Невірний формат email";return fn;})(),
      "surnameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище не повинно бути пустим"])};fn.source="Прізвище не повинно бути пустим";return fn;})(),
      "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я не повинно бути пустим"])};fn.source="Ім'я не повинно бути пустим";return fn;})(),
      "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле повинно містити принаймні 3 символа"])};fn.source="Поле повинно містити принаймні 3 символа";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])};fn.source="Неправильний пароль";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])};fn.source="Неправильний пароль";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен бути порожнім"])};fn.source="Пароль не повинен бути порожнім";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не можемо знайти користувача з цим email адресою."])};fn.source="Ми не можемо знайти користувача з цим email адресою.";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон не може бути порожнім"])};fn.source="Телефон не може бути порожнім";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте довжину телефону"])};fn.source="Перевірте довжину телефону";return fn;})()
      }
    }
  },
  "pselection": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір програми лояльності"])};fn.source="Вибір програми лояльності";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть та налаштуйте свою програму лояльності"])};fn.source="Виберіть та налаштуйте свою програму лояльності";return fn;})(),
    "radioButton": {
      "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])};fn.source="Знижка";return fn;})(),
      "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус"])};fn.source="Бонус";return fn;})()
    },
    "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])};fn.source="Далі";return fn;})(),
    "discount": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисконтна програма лояльності"])};fn.source="Дисконтна програма лояльності";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування прямих знижок для вашого клієнта"])};fn.source="Налаштування прямих знижок для вашого клієнта";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування рівнів"])};fn.source="Налаштування рівнів";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть потрібну кількість рівнів, їх суму та відсоток знижки"])};fn.source="Виберіть потрібну кількість рівнів, їх суму та відсоток знижки";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рівня"])};fn.source="Назва рівня";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума від"])};fn.source="Сума від";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до"])};fn.source="Сума до";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка, %"])};fn.source="Знижка, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати рівень"])};fn.source="Додати рівень";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])};fn.source="Знижка";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])};fn.source="Власник карти";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilDiscount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До знижки"])};fn.source="До знижки";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])};fn.source="Ім'я Прізвище";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування знижок збережено!"])};fn.source="Налаштування знижок збережено!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштувань знижок!"])};fn.source="Помилка збереження налаштувань знижок!";return fn;})()
      }
    },
    "bonus": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусна система лояльності"])};fn.source="Бонусна система лояльності";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте свою бонусну програму"])};fn.source="Налаштуйте свою бонусну програму";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування рівнів"])};fn.source="Налаштування рівнів";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть потрібну кількість рівнів, їх суму та відсоток знижки"])};fn.source="Виберіть потрібну кількість рівнів, їх суму та відсоток знижки";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рівня"])};fn.source="Назва рівня";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума від"])};fn.source="Сума від";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до"])};fn.source="Сума до";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус, %"])};fn.source="Бонус, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати  рівень"])};fn.source="Додати  рівень";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])};fn.source="Знижка";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])};fn.source="Баланс";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])};fn.source="Власник карти";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])};fn.source="До бонуса";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])};fn.source="Ім'я Прізвище";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування бонусів збережено!"])};fn.source="Налаштування бонусів збережено!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштувань бонусів!"])};fn.source="Помилка збереження налаштувань бонусів!";return fn;})()
      }
    },
    "coupon": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купонна програма лояльності"])};fn.source="Купонна програма лояльності";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система лояльності дозволяє створювати одноразові купони на знижку (подарункові сертифікати) з різними номіналами."])};fn.source="Система лояльності дозволяє створювати одноразові купони на знижку (подарункові сертифікати) з різними номіналами.";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])};fn.source="Знижка";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])};fn.source="Власник карти";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilDiscount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До знижки"])};fn.source="До знижки";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])};fn.source="Ім'я Прізвище";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])};fn.source="coupon";return fn;})(),
        "accumulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["накопичення"])};fn.source="накопичення";return fn;})(),
        "congratulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["привітання"])};fn.source="привітання";return fn;})()
      }
    },
    "clubcard": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клубна карта"])};fn.source="Клубна карта";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередній перегляд клубної картки"])};fn.source="Попередній перегляд клубної картки";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])};fn.source="Знижка";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])};fn.source="Баланс";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])};fn.source="Власник карти";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонусу"])};fn.source="До бонусу";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])};fn.source="Ім'я Прізвище";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["купон"])};fn.source="купон";return fn;})()
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])};fn.source="Бренд";return fn;})(),
      "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])};fn.source="Wallet";return fn;})(),
      "qr-catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})()
    },
    "qrCatalog": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте своє Онлайн-меню"])};fn.source="Налаштуйте своє Онлайн-меню";return fn;})(),
      "colors": {
        "primary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту"])};fn.source="Колір тексту";return fn;})(),
        "secondary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір заголовка"])};fn.source="Колір заголовка";return fn;})(),
        "relief-1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір фону"])};fn.source="Колір фону";return fn;})(),
        "relief-2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір категорії"])};fn.source="Колір категорії";return fn;})(),
        "relief-7": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту ціни"])};fn.source="Колір тексту ціни";return fn;})(),
        "relief-6": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір фону ціни"])};fn.source="Колір фону ціни";return fn;})()
      },
      "logoTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для каталогу"])};fn.source="Логотип для каталогу";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип текст для каталогу"])};fn.source="Логотип текст для каталогу";return fn;})(),
      "downloadLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження QR Code"])};fn.source="Завантаження QR Code";return fn;})(),
      "previewLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляд"])};fn.source="Перегляд";return fn;})(),
      "previewLinkHint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі зміни будуть доступні після натискання на кнопку \"Опублікувати\""])};fn.source="Всі зміни будуть доступні після натискання на кнопку \"Опублікувати\"";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])};fn.source="Зберегти";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню налаштування збережені!"])};fn.source="Онлайн-меню налаштування збережені!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштування Онлайн-меню!"])};fn.source="Помилка збереження налаштування Онлайн-меню!";return fn;})()
      }
    },
    "wallet": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet для карток"])};fn.source="Wallet для карток";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування Wallet для карток"])};fn.source="Налаштування Wallet для карток";return fn;})(),
      "cardColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір картки"])};fn.source="Колір картки";return fn;})(),
      "textColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту"])};fn.source="Колір тексту";return fn;})(),
      "titleColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір заголовка"])};fn.source="Колір заголовка";return fn;})(),
      "logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для Wallet"])};fn.source="Логотип для Wallet";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст логотипу для Wallet"])};fn.source="Текст логотипу для Wallet";return fn;})(),
      "centralImage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральне зображення для Wallet"])};fn.source="Центральне зображення для Wallet";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])};fn.source="Зберегти";return fn;})(),
      "preview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередній перегляд"])};fn.source="Попередній перегляд";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування Wallet збережені!"])};fn.source="Налаштування Wallet збережені!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час збереження налаштувань Wallet!"])};fn.source="Помилка під час збереження налаштувань Wallet!";return fn;})()
      }
    }
  },
  "leftSideMenu": {
    "agents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенти"])};fn.source="Агенти";return fn;})(),
    "averageСheck": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середній чек"])};fn.source="Середній чек";return fn;})(),
    "catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
    "clientData": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные клиента"])};fn.source="Данные клиента";return fn;})(),
    "crm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])};fn.source="CRM";return fn;})(),
    "dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])};fn.source="Статистика";return fn;})(),
    "faq": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])};fn.source="FAQ";return fn;})(),
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])};fn.source="Зворотній зв'язок";return fn;})(),
    "for-client": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для клієнтів"])};fn.source="Для клієнтів";return fn;})(),
    "for-staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для персоналу"])};fn.source="Для персоналу";return fn;})(),
    "history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія"])};fn.source="Історія";return fn;})(),
    "integrations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтеграції"])};fn.source="Інтеграції";return fn;})(),
    "levelRetention": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утримання клієнтів"])};fn.source="Утримання клієнтів";return fn;})(),
    "levelSatisfaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задоволеність клієнтів"])};fn.source="Задоволеність клієнтів";return fn;})(),
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])};fn.source="Головна";return fn;})(),
    "merchants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнти"])};fn.source="Клієнти";return fn;})(),
    "pointsOfSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продажу"])};fn.source="Точки продажу";return fn;})(),
    "profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])};fn.source="Профіль";return fn;})(),
    "pselection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програма лояльності"])};fn.source="Програма лояльності";return fn;})(),
    "staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонал"])};fn.source="Персонал";return fn;})(),
    "statistic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])};fn.source="Статистика";return fn;})(),
    "support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка"])};fn.source="Підтримка";return fn;})(),
    "topSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ продажів"])};fn.source="Топ продажів";return fn;})(),
    "transaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакциії"])};fn.source="Транзакциії";return fn;})(),
    "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта Wallet"])};fn.source="Карта Wallet";return fn;})(),
    "departments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відділення"])};fn.source="Відділення";return fn;})()
  },
  "application": {
    "imageForBothTheme": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])};fn.source="Use the downloaded image for both themes in the app";return fn;})()
  },
  "locale": {
    "uk": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])};fn.source="Укр";return fn;})(),
    "ru": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])};fn.source="Рус";return fn;})(),
    "en": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])};fn.source="Eng";return fn;})()
  },
  "login": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо"])};fn.source="Ласкаво просимо";return fn;})(),
    "forgotQuery": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])};fn.source="Забули пароль?";return fn;})(),
      "sub": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть e-mail, вказаний під час реєстрації. Ми надішлемо вам посилання для скидання пароля."])};fn.source="Введіть e-mail, вказаний під час реєстрації. Ми надішлемо вам посилання для скидання пароля.";return fn;})(),
      "messageSuccessReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми відправили посилання для відновлення пароля на вказаний Email. "])};fn.source="Ми відправили посилання для відновлення пароля на вказаний Email. ";return fn;})()
    },
    "pleaseSignIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть у свій обліковий запис"])};fn.source="Увійдіть у свій обліковий запис";return fn;})(),
    "buttonLogin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])};fn.source="Увійти";return fn;})(),
    "buttonReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути пароль"])};fn.source="Скинути пароль";return fn;})(),
    "rememberMe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запам'ятати мене"])};fn.source="Запам'ятати мене";return fn;})(),
    "forgotPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])};fn.source="Забули пароль?";return fn;})(),
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний email"])};fn.source="Неправильний email";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронну пошту не знайдено"])};fn.source="Електронну пошту не знайдено";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не повинен бути порожнім"])};fn.source="Email не повинен бути порожнім";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна адреса недійсна"])};fn.source="Електронна адреса недійсна";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])};fn.source="Неправильний пароль";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])};fn.source="Неправильний пароль";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен бути порожнім"])};fn.source="Пароль не повинен бути порожнім";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не можемо знайти користувача з цією електронною адресою."])};fn.source="Ми не можемо знайти користувача з цією електронною адресою.";return fn;})()
    }
  },
  "menu": {
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна сторінка"])};fn.source="Основна сторінка";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])};fn.source="Логін";return fn;})(),
    "registration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])};fn.source="Реєстрація";return fn;})()
  },
  "form": {
    "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])};fn.source="Електронна пошта";return fn;})(),
    "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])};fn.source="Пароль";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])};fn.source="Логін";return fn;})(),
    "register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])};fn.source="Реєстрація";return fn;})()
  },
  "main": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", бажаєте почати налаштування?"])};fn.source="{name}, бажаєте почати налаштування?";return fn;})(),
    "signout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])};fn.source="Вийти";return fn;})(),
    "new_message": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Немає повідомлень"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлень"])])};fn.source="Немає повідомлень | {count} повідомлення  | {count} повідомлення | {count} повідомлень";return fn;})()
  },
  "nav": {
    "account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особистий кабінет"])};fn.source="Особистий кабінет";return fn;})(),
    "profiel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])};fn.source="Профіль";return fn;})(),
    "loyalty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програма лояльності"])};fn.source="Програма лояльності";return fn;})(),
    "bank-links": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковські посилання"])};fn.source="Банковські посилання";return fn;})()
  },
  "points": {
    "workTime": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим роботи"])};fn.source="Режим роботи";return fn;})()
    },
    "departments": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відділення"])};fn.source="Відділення";return fn;})()
    },
    "titlenameNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова точка продажу"])};fn.source="Нова точка продажу";return fn;})(),
    "subtitleNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нову точку продажу. Після додавання інформації натисніть \"Зберегти\"."])};fn.source="Додати нову точку продажу. Після додавання інформації натисніть \"Зберегти\".";return fn;})(),
    "titlenameEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати точку"])};fn.source="Редагувати точку";return fn;})(),
    "subtitleEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відредагуйте точку, щоб клієнти могли зв'язатися з вами"])};fn.source="Відредагуйте точку, щоб клієнти могли зв'язатися з вами";return fn;})(),
    "phone": {
      "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити номер телефона"])};fn.source="Видалити номер телефона";return fn;})(),
      "addPhone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати телефон"])};fn.source="Додати телефон";return fn;})()
    },
    "btnNewPoint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати точку продажу"])};fn.source="Додати точку продажу";return fn;})(),
    "table": {
      "header": {
        "point": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОЧКИ ПРОДАЖУ"])};fn.source="ТОЧКИ ПРОДАЖУ";return fn;})(),
        "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АДРЕСА"])};fn.source="АДРЕСА";return fn;})(),
        "qrQatalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
        "phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕЛЕФОН"])};fn.source="ТЕЛЕФОН";return fn;})(),
        "viber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])};fn.source="VIBER";return fn;})(),
        "whatsapp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])};fn.source="WHATSAPP";return fn;})(),
        "telegram": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])};fn.source="TELEGRAM";return fn;})(),
        "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДІЇ"])};fn.source="ДІЇ";return fn;})()
      },
      "body": {
        "emptyMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви ще не додали жодної точки продажу."])};fn.source="Ви ще не додали жодної точки продажу.";return fn;})()
      },
      "delete": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити контакт"])};fn.source="Видалити контакт";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете видалити контакт? Всі дані будуть видалені"])};fn.source="Ви впевнені, що хочете видалити контакт? Всі дані будуть видалені";return fn;})(),
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])};fn.source="Скасувати";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])};fn.source="Видалити";return fn;})()
      }
    },
    "address": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])};fn.source="Адрес";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почніть вводити свою адресу"])};fn.source="Почніть вводити свою адресу";return fn;})()
    },
    "title": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])};fn.source="Назва";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву"])};fn.source="Введіть назву";return fn;})()
    },
    "subdomain": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я"])};fn.source="Доменне ім'я";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть доменне ім'я"])};fn.source="Введіть доменне ім'я";return fn;})()
    },
    "errors": {
      "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса не може бути порожньою"])};fn.source="Адреса не може бути порожньою";return fn;})(),
      "subdomain": {
        "required": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я не може бути порожнім"])};fn.source="Доменне ім'я не може бути порожнім";return fn;})(),
        "latin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допускаються лише латинські символи"])};fn.source="Допускаються лише латинські символи";return fn;})(),
        "exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я вже існує"])};fn.source="Доменне ім'я вже існує";return fn;})()
      },
      "domain_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт не збережено. Таке доменне ім'я вже існує"])};fn.source="Контакт не збережено. Таке доменне ім'я вже існує";return fn;})(),
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва не може бути порожньою"])};fn.source="Назва не може бути порожньою";return fn;})(),
      "title_symbols": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле має хибний формат"])};fn.source="Поле має хибний формат";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон не може бути порожнім"])};fn.source="Телефон не може бути порожнім";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])};fn.source="Phone length check";return fn;})()
      }
    },
    "catalog": {
      "point": {
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])};fn.source="Точка";return fn;})(),
        "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть точку"])};fn.source="Виберіть точку";return fn;})()
      },
      "copy": {
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати Онлайн-меню із попереднього пункту?"])};fn.source="Копіювати Онлайн-меню із попереднього пункту?";return fn;})(),
        "yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])};fn.source="Так";return fn;})(),
        "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])};fn.source="Ні";return fn;})()
      }
    }
  },
  "catalog": {
    "sale_channels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продажу"])};fn.source="Точки продажу";return fn;})(),
    "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товари та послуги"])};fn.source="Товари та послуги";return fn;})(),
    "qr_catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній вигляд каталогу"])};fn.source="Зовнішній вигляд каталогу";return fn;})(),
    "point": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])};fn.source="Точка";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть точку"])};fn.source="Виберіть точку";return fn;})(),
      "not_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед початком роботи з каталогом виберіть точку"])};fn.source="Перед початком роботи з каталогом виберіть точку";return fn;})(),
      "list_is_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом створіть точку"])};fn.source="Перед началом работы с каталогом створіть точку";return fn;})()
    }
  },
  "products": {
    "errors": {
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не може бути порожнім"])};fn.source="Поле не може бути порожнім";return fn;})()
    }
  },
  "_": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})()
}