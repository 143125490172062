<template>
  <vue-final-modal
    class="resolution-alert"
    overlay-class="resolution-alert__overlay"
    background="non-interactive"
    :click-to-close="true"
    :esc-to-close="true"
  >
    <div class="mobile-warning-screen">
      <div class="content">
        <div class="header">Пополнение депозита клиента</div>
        <!-- <div class="desc">Пополнить депозит клубной карты.</div> -->
      </div>
      <Cell
        caption="ID клиента"
        desc="(Введите ID клиента которому пополняете депозит)"
        class="cell-item"
      >
        <ErrorCheck
          :is-error="v$?.userID?.$error"
          :error-message="v$?.userID?.$errors[0]?.$message"
          :error-object="v$.userID"
        >
          <text-input
            v-model="deposit.userID"
            no-title
            placeholder="ID клиента"
            @focus="v$.$reset()"
          />
        </ErrorCheck>
      </Cell>
      <Cell
        caption="Сумма пополнения"
        desc="(Введите сумму на которую вы хотите пополнить депозит)"
        class="cell-item"
      >
        <ErrorCheck
          :is-error="v$?.amount?.$error"
          :error-message="v$?.amount?.$errors[0]?.$message"
        >
          <price-input
            v-model="deposit.amount"
            no-title
            placeholder="Введите сумму"
            min="0"
            :currency-code="currentCurrency.currencyCode"
          />
        </ErrorCheck>
      </Cell>
      <div class="buttons">
        <action-button class="back"
@click="() => emit('close')">
          Отмена
        </action-button>
        <action-button action
class="add"
@click="saveTopUpDeposit">
          Пополнить
        </action-button>
      </div>
      <svg
        class="x-close"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="emit('close')"
      >
        <path
          d="M18.9961 6L6.99609 18M6.99609 6L18.9961 18"
          stroke="#0E0E22"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </vue-final-modal>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
// import { useRouter } from "vue-router";
import { VueFinalModal } from "vue-final-modal";
import Cell from "@/components/app/rows/Cell.vue";
import TextInput from "@/components/UI/inputs/TextInput.vue";
import PriceInput from "@/components/UI/inputs/PriceInput.vue";
import { required, helpers, not, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { updateDeposit } from "@/services/loyalty.service";

import { useToast } from "vue-toastification";
const toast = useToast();

const { withAsync, withMessage } = helpers;
const currentCurrency = ref({ currencySign: "" });
const deposit = reactive({
  userID: null,
  amount: null,
});
const userIDNotEmpty = computed(() => "ID не может быть пустым");
const amountNotEmpty = computed(() => "Сумма не может быть пустой");
const amountNotZero = computed(() => "Сумма не может быть нулевой");
const idNotInStates = computed(() => "Данный ID отсутствует в базе");

const rules = computed(() => {
  return {
    userID: {
      required: withMessage(userIDNotEmpty, required),
    },
    amount: {
      required: withMessage(amountNotEmpty, required),
      not: withMessage(amountNotZero, not(sameAs(0))),
    },
  };
});
const v$ = useVuelidate(rules, deposit);
async function saveTopUpDeposit() {
  // v$.value.$reset();
  const resultValidate = await v$.value.$validate();
  if (!resultValidate) return;
  await updateDeposit({ userId: deposit.userID, amount: +deposit.amount });
  toast.success(`Депозит обновлён.`);
  emit("confirm", deposit);
}
// eslint-disable-next-line no-undef
defineProps({});
// const router = useRouter();
// eslint-disable-next-line no-undef
const emit = defineEmits({
  close: null,
  confirm: null,
});
onMounted(async () => {});
</script>

<style lang="scss" scoped>
.mobile-warning-screen,
.mobile-warning-screen * {
  box-sizing: border-box;
}
.mobile-warning-screen {
  background: var(--maincolors-white, #ffffff);
  border-radius: 20px;
  padding: 32px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  /* width: 420px; */
  position: relative;
  box-shadow: var(
    --shadow-medium-box-shadow,
    0px 4px 40px 0px rgba(0, 0, 0, 0.08)
  );
}
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.header {
  color: var(--maincolors-header, #0e0e22);
  text-align: center;
  font: var(--dashboard-h-1, 600 20px/110% "Montserrat", sans-serif);
  position: relative;
  align-self: stretch;
}
.desc {
  color: var(--maincolors-text, #595a5b);
  text-align: center;
  font: var(--dashboard-textmd, 400 14px/130% "Inter", sans-serif);
  position: relative;
  align-self: stretch;
}
.buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.x-close {
  flex-shrink: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  overflow: visible;
  cursor: pointer;
}
.back,
.add {
  flex: 1;
}
</style>
