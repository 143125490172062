import { createApp } from "vue";

import App from "@/App.vue";
import "./firebase";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import filters from "@/plugins/Filters";
import Console from "@/plugins/Console";
import "@/assets/scss/main.scss";
import VueCreditCardValidation from "vue-credit-card-validation";
import Markdown from "vue3-markdown-it";
import VueSmoothScroll from "vue3-smooth-scroll";
import Toast, { POSITION, TYPE } from "vue-toastification";
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";
const vfm = createVfm();

import { useAccordion } from "vue3-rich-accordion";
import "vue3-rich-accordion/accordion-library-styles.css";
import PrimeVue from "primevue/config";
const toastOption = {
  toastDefaults: {
    [TYPE.ERROR]: {
      icon: "icon icon-exclamation",
    },
    [TYPE.SUCCESS]: {
      icon: "icon icon-checkmark",
    },
    [TYPE.INFO]: {
      icon: "icon icon-alert",
    },
    [TYPE.WARNING]: {
      icon: "icon icon-information",
    },
  },
  closeButton: "div",
  toastClassName: "toast-class",
  timeout: 2000,
  position: POSITION.TOP_RIGHT,
  hideProgressBar: true,
};

import appComponents from "@/components";
import appDirectives from "@/directives";

const app = createApp(App);

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast, toastOption)
  .use(VueCreditCardValidation)
  .use(filters)
  .use(Console, { prefix: "qbeek ->" })
  .use(appComponents)
  .use(appDirectives)
  .use(VueSmoothScroll)
  .use(vfm)
  .use(useAccordion)
  .use(Markdown)
  .use(PrimeVue, {
    unstyled: true,
  })
  .mount("#app");
