// plugins/axios.js
import axios from 'axios'
import { useAuth } from '@/composables/useAuth'

function setupAxiosInterceptors() {
  const { accessToken, refreshAccessToken, logout, isTokenExpired } = useAuth()
  const axiosAuthInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_AUTH_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // Request interceptor
  axiosAuthInstance.interceptors.request.use(
    async (config) => {
      if (accessToken.value && isTokenExpired()) {
        try {
          await refreshAccessToken()
        } catch (error) {
          // Token refresh failed, redirect to login
          window.location.href = '/login'
          return Promise.reject(error)
        }
      }
      if (accessToken.value) {
        config.headers.Authorization = `Bearer ${accessToken.value}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosAuthInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      console.log('Interceptor Error:', error?.response?.status, originalRequest);

      // Если ошибка 401 и запрос еще не был повторен
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          // Обновляем токен и перезаписываем accessToken.value
          await refreshAccessToken()

          // Добавляем новый токен в заголовки
          originalRequest.headers.Authorization = `Bearer ${accessToken.value}`

          // Повторяем запрос через axiosAuthInstance
          return axiosAuthInstance(originalRequest)
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError)

          // Перенаправление на страницу входа или выход из системы
          // await logout()

          return Promise.reject(refreshError)
        }
      }

      return Promise.reject(error)
    }
  )

}
export default setupAxiosInterceptors 