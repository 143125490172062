<template>
  <div class="auth-wrapper">
    <div class="auth-image" />
    <div class="main-container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  component: {},
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  display: flex;
}
.auth-image {
  width: 35%;
  background-image: url("@/assets/img/login-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.main-container {
  display: flex;
  justify-content: center;
  flex: 1;
}
/* @media screen and (max-width: 1024px) {
  .auth-wrapper {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    background-color: rgba(76, 78, 100, 0.05);
    // background-size: calc(100% - 106px) auto;
    background: "none";
    // background-repeat: no-repeat;
    // background-position: 105% calc(100% - 26px);
    // display: flex;
    // justify-content: right;
    // padding-right: 15%;
  }
} */
</style>
