<template>
  <svg width="32"
height="32"
viewBox="0 0 32 32"
fill="none"
xmlns="http://www.w3.org/2000/svg">
    <path d="M26 10.0002C26 9.46975 25.7893 8.96104 25.4142 8.58597C25.0391 8.2109 24.5304 8.00018 24 8.00018H19L26 20.0002"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M26 25.0002C28.7614 25.0002 31 22.7616 31 20.0002C31 17.2388 28.7614 15.0002 26 15.0002C23.2386 15.0002 21 17.2388 21 20.0002C21 22.7616 23.2386 25.0002 26 25.0002Z"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M6 25.0002C8.76142 25.0002 11 22.7616 11 20.0002C11 17.2388 8.76142 15.0002 6 15.0002C3.23858 15.0002 1 17.2388 1 20.0002C1 22.7616 3.23858 25.0002 6 25.0002Z"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M6 8.00018H9.5L16.5 20.0002"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
    <path d="M21.2609 12.0002H11.8334L6 20.0002"
stroke="#595A5B"
stroke-width="2"
stroke-linecap="round"
stroke-linejoin="round"/>
  </svg>
</template>