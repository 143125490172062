import axios from "axios";
import { useAuth } from "@/composables/useAuth";
import router from "@/router";
// services/apiService.js
const { accessToken, refreshAccessToken, logout, isTokenExpired } = useAuth()

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL, //"http://13.229.219.131:7000",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    if (accessToken.value && isTokenExpired()) {
      try {
        await refreshAccessToken()
      } catch (error) {
        // Token refresh failed, redirect to login
        window.location.href = '/login'
        return Promise.reject(error)
      }
    }

    if (accessToken.value) {
      config.headers.Authorization = `Bearer ${accessToken.value}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    let errorMessage = "An error occurred"; // Сообщение по умолчанию

    console.error("Interceptor Error:", error);

    // Таймаут запроса
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      errorMessage = "Error: Request timeout exceeded (10 sec)";
      return Promise.resolve({ success: false, message: 'Request timed out', data: [] });
    }

    // Ошибки с ответом от сервера
    if (error.response) {
      const { status } = error.response;

      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Обновление токена
          await refreshAccessToken();

          // Добавляем новый токен и повторяем запрос
          originalRequest.headers.Authorization = `Bearer ${accessToken.value}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);

          // Разлогиниваем пользователя при неудаче
          await logout();
          router.push({ name: 'login' });
          return Promise.reject(refreshError);
        }
      }

      // Обработка других HTTP-ошибок
      switch (status) {
        case 404:
          errorMessage = "Error 404: Resource not found";
          break;
        case 400:
          errorMessage = "Error 400: Bad Request";
          break;
        default:
          errorMessage = "Unexpected error occurred";
      }
    } else {
      // Ошибка сети (например, нет интернета)
      errorMessage = error.message || "Network error";
    }

    console.error("Final Error Message:", errorMessage);
    return Promise.reject(errorMessage);
  }
);

export default axiosInstance;

export const getMenuItems = async (data, headers = {}) => {
  const url = "/menu-items";
  headers["target"] = "test-pos";
  const response = await axiosInstance.post(url, data, { headers });
  return response.items;
};
