import { computed } from "vue";
import { db } from "@/firebase";
import axiosInstance from "./axios.service";

import {
  // collection,
  // getDocs,
  onSnapshot,
  query,
  where,
  // orderBy,
  // limit,
  doc,
  getDoc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import store from "@/store";

const merchant = computed(() => store.getters.merchant);

/* GET POINTS NAME */

export async function getPoints() {
  //deprecated
  try {
    const url = `/operator/points/list`;
    const response = await axiosInstance.get(url);
    console.log("getPoints:response", response);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getPointsAddress() {
  try {
    const { merchantId } = merchant.value;
    const url = `/operator/points/addresses`;
    const response = await axiosInstance.get(url);
    console.log("getPointsAddress:response", response);

    return response;
  } catch (error) {
    console.error(error);
  }
  // return tempArr;
}

export async function getPointsName() {
  const { merchantId } = merchant.value;
  try {
    const url = `/operator/points/names`;
    // const url = `/qbeek/merchant/points-list`;

    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getPoint(pointId) {
  const url = `/operator/points/list/${pointId}`;
  try {
    const result = await axiosInstance.get(url);

    return result;
  } catch (error) {
    console.error(error);
  }
}

/* ===========Calls=============== */

/**
 * Retrieves the crew members based on the merchant ID and role.
 * @param {Function} callback - The callback function to be called with the retrieved crew members.
 * @returns {Promise<void>} - A promise that resolves when the crew members are retrieved.
 */
export async function getCrew(pointId, callback) {
  const url = `/operator/personal/employee-list/${pointId}`;
  const response = await axiosInstance.get(url);

  const data = response;
  callback(data);
}

/**
 * Deletes a shift from the database.
 * @param {string} shiftId - The ID of the shift to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the shift is successfully deleted.
 */
export async function deleteShift(shiftId) {
  try {
    const url = `/operator/workaround/shifts/${shiftId}`;
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Deletes a hall from the database.
 * @param {string} hallId - The ID of the hall to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the hall is successfully deleted.
 */
export async function deleteHall(hallId) {
  try {
    const url = `/operator/workaround/halls/${hallId}`;
    const response = await axiosInstance.delete(url);
    console.log("response:deleteHall", response);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the last hall number for a given point ID.
 *
 * @param {string} pointId - The ID of the point.
 * @returns {Promise<number>} - The last hall number.
 */
export async function getLastHallNumber(pointId) {
  const url = `/operator/workaround/halls/${pointId}/numtags`;
  try {
    const resp = await axiosInstance.get(url);

    if (!resp.length) {
      return 0;
    }
    const result = +Math.max(...resp) || 0;
    return result;
  } catch (error) {
    console.error(error);
  }
}

export async function getHallNumtags(pointId) {
  const url = `/operator/workaround/halls/${pointId}/numtags`;
  try {
    const result = await axiosInstance.get(url);
    // if (!result) {
    //   return 1;
    // }
    return result || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}
export async function updateShift(shift) {
  const url = `/operator/workaround/shifts/update/${shift.id}`;
  try {
    const response = await axiosInstance.post(url, shift);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function createShift(shift) {
  const url = `/operator/workaround/shifts/create`;
  try {
    const response = await axiosInstance.post(url, shift);
    return response;
  } catch (error) {
    console.error(error);
  }
}
// /operator/workaround/shifts/create
export async function updateWorkzone(wz) {
  const url = `/operator/workaround/workzones/update/${wz.id}`;
  console.log("wz", wz);
  console.log("url", url);

  try {
    const response = await axiosInstance.post(url, wz);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getWorkzone() {
  const result = {};

  const url = `/operator/workaround/workzones/list
`;
  try {
    const querySnapshot = await axiosInstance.get(url);
    console.log("querySnapshot:result:points-service.js", querySnapshot);
    querySnapshot.forEach((docData) => {
      if (result[docData.pointId]) {
        result[docData.pointId].push(docData);
      } else {
        result[docData.pointId] = [docData];
      }
    });

    return result;
  } catch (error) {
    console.error(error);
  }
}
// export async function updateEmployees(shift) {
//   const urlMap = (docId) => `/qbeek/merchant/employees/update/${docId}`;
//   const crewForUpdate = [];
//   try {
//     shift.crew.forEach(async (empl) => {
//       const newRec = {
//         id: empl.id,
//         shiftId: shift.id,
//         pointId: shift.pointId,
//       };
//       const url = urlMap(empl.id);
//       crewForUpdate.push(
//         axiosInstance.post(url, JSON.parse(JSON.stringify(newRec)))
//       );
//     });
//     return await Promise.all(crewForUpdate);
//   } catch (error) {
//     console.error(error);
//   }
// }

/**
 * Updates a hall by its point ID.
 * @param {string} pointId - The ID of the point.
 * @param {object} hall - The hall object to update.
 * @returns {Promise<boolean>} - A promise that resolves to true if the update is successful, or rejects with an error if it fails.
 */
export async function updateHall(hall) {
  const url = `/operator/workaround/halls/update/${hall.id}`;
  try {
    const result = await axiosInstance.post(url, hall);
    return result;
  } catch (error) {
    console.error(error);
  }
}
export async function createHall(hall) {
  const url = `/operator/workaround/halls/create
`;
  try {
    const result = await axiosInstance.post(url, hall);
    return result;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Retrieves halls by point ID.
 * @param {string} pointId - The ID of the point.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of hall objects.
 */
export async function getHallsByPointId(pointId) {
  const url = `/operator/workaround/halls/points/${pointId}`;
  try {
    const responseSnapshot = await axiosInstance.get(url);
    console.log("response:getHallsByPointId", responseSnapshot);
    return responseSnapshot;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a hall from the database based on the provided hallId.
 * @param {string} hallId - The ID of the hall to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the hall object.
 */
export async function getHall(hallId) {
  const url = `/operator/workaround/halls/read/${hallId}`;
  try {
    const responseSnapshot = await axiosInstance.get(url);
    console.log("response:getHall", responseSnapshot);

    return responseSnapshot /* ?.find((el) => el.id === hallId) */;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a shift based on the shift ID.
 * @param {string} shiftId - The ID of the shift to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the shift object if found, or null if not found.
 */
export async function getShift(shiftId) {
  try {
    const url = `/operator/workaround/shifts/read/${shiftId}`;
    const response = await axiosInstance.get(url);
    return response; //.find((shift) => shift.id === shiftId);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves all halls associated with the current merchant.
 * @returns {Object} An object containing the halls grouped by pointId.
 */
export async function getAllHalls() {
  const result = {};
  const url = `/operator/workaround/halls/list
`;
  try {
    const resSnapshot = await axiosInstance.get(url);
    resSnapshot.forEach((docData) => {
      if (!docData.disabled) {
        if (result[docData.pointId]) {
          result[docData.pointId].push(docData);
        } else {
          result[docData.pointId] = [docData];
        }
      }
    });
    return result;
  } catch (error) {
    console.error(error);
  }
}
export async function getAllUsers() {
  const result = {};
  const url = `/operator/personal/employee-list
`;
  try {
    const resultSnapshot = await axiosInstance.get(url);
    console.log("result:getAllUsers", result);
    resultSnapshot.forEach((docData) => {
      if (!docData?.disabled) {
        if (Array.isArray(docData.point)) {
          for (const p of docData.point) {
            if (result[p.id]) {
              result[p.id].push(docData);
            } else {
              result[p.id] = [docData];
            }
          }
        } else {
          result[docData.point] = [docData];
        }
      }
    });
    return result;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Retrieves all shifts associated with the current merchant.
 * @returns {Promise<Object>} A promise that resolves to an object containing the shifts grouped by pointId.
 */
export async function getAllShifts() {
  const result = {};
  const url = `/operator/workaround/shifts/list
`;
  try {
    const snapshot = await axiosInstance.get(url);
    const result = {};
    snapshot.map((doc) => {
      const docData = doc;
      if (result[docData.pointId]) {
        result[docData.pointId].push(docData);
      } else {
        result[docData.pointId] = [docData];
      }
    });
    return result;
  } catch (error) {
    console.error(error);
  }
}

export async function updateQrCode(qrcode) {
  // const { merchantId } = merchant.value;
  const url = `/operator/qr-code-hash/update/${qrcode.id}`;
  try {
    const result = await axiosInstance.put(url, qrcode);
    return result;
  } catch (error) {
    console.error(error);
  }
}

export async function getPointsData() {
  const url = "/operator/points/list";
  try {
    const result = await axiosInstance.get(url);
    return result?.filter((point) => !point.disabled);
  } catch (error) {
    console.error(error);
  }
}

/* ===DEPARTMENTS=== */
export async function createDepartment(data, headers = {}) {
  const url = "/operator/departments/create";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log("response:createDepartment", response);
    return response;
  } catch (error) {
    console.error("Error posting data=createDepartment=>:", error);
    return { status: 400, message: error };
  }
} //Create
export async function updateDepartment(data, headers = {}) {
  const url = "/operator/departments/update";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log("response:updateDepartment", response);
    return response;
  } catch (error) {
    console.error("Error posting data=updateDepartment=>:", error);
    return { status: 400, message: error };
  }
} //Update
export async function deleteDepartment(data, headers = {}) {
  const url = "/operator/departments/delete";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log("response:deleteDepartment", response);
    return response;
  } catch (error) {
    console.error("Error posting data=deleteDepartment=>:", error);
    return { status: 400, message: error };
  }
} //Delete

// /operator/departments/create
// /operator/departments/update
// /operator/departments/delete
// /operator/departments/point
export async function getDepartments(data, headers = {}) {
  const url = "/operator/departments/point";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=pointDepartment=>:", error);
    return { status: 400, message: error };
  }
} //point
export async function getMerchantPointsDepartments(data, headers = {}) {
  const url = "/operator/departments/merchant";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=getMerchantPointsDepartments=>:", error);
    return { status: 400, message: error };
  }
} //point
export async function getPointsList(data = {}, headers = {}) {
  const url = "/operator/points/list";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log('response:getPointsList', response);

    return response;
  } catch (error) {
    console.error("Error posting data=getPointsList=>:", error);
    return { status: 400, message: error };
  }
} //points list

export async function getPointNameList(data, headers = {}) {
  const url = "/operator/points/list";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response.map((p) => ({ title: p.title, id: p.id }));
  } catch (error) {
    console.error("Error posting data=getPointsList=>:", error);
    return { status: 400, message: error };
  }
} //points list

export async function getPointById(data, headers = {}) {
  const url = "/operator/point/load";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=getPointById=>:", error);
    return { status: 400, message: error };
  }
} //getPointById

export async function updatePoint(data, headers = {}) {
  const url = "/operator/point/update";
  try {
    console.log('data:updatePoint', data);

    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=updatePoint=>:", error);
    return { status: 400, message: error };
  }
} //updatePoint
export async function createPoint(data, headers = {}) {
  const url = "/operator/point/create";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=createPoint=>:", error);
    return { status: 400, message: error };
  }
}
export async function disablePoint(data, headers = {}) {
  const url = "/operator/point/disable";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error("Error posting data=disablePoint=>:", error);
    return { status: 400, message: error };
  }
} //disablePoint
