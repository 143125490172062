<template>
  <button
    :class="[
      'button',
      {
        action,
        off,
        w100p,
        w40p,
        variant1,
        variant2,
        variant3,
        variant4,
        variant5,
        variant6,
        black,
        red,
        hover,
        'full-black': fullBlack,
        'full-red': fullRed,
        'button--loading': spin,
        'payment-done': paymentDone,
      },
    ]"
    :style="inlineStyle"
    v-bind="$attrs"
    type="button"
    :disabled="disabled || spin"
  >
    <div :class="['btn-text', { variant2 }]">
      <slot v-if="icon"
name="icon">
        <v-icon
          name="plus-btn"
          width="20"
          height="20"
          :stroke="
            off
              ? '#bcbbbb'
              : disabled
              ? '#bcbbbb'
              : action
              ? '#ffffff'
              : hover
              ? '#fff'
              : black
              ? '#0e0e22'
              : red
              ? '#fe4a49'
              : fullBlack
              ? '#fff'
              : '#00cdd7'
          "
        />
      </slot>
      <slot />
    </div>
  </button>
</template>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  action: {
    type: Boolean,
    default: () => false,
  },
  icon: {
    type: Boolean,
    default: () => false,
  },
  spin: {
    type: Boolean,
    default: () => false,
  },
  off: {
    type: Boolean,
    default: () => false,
  },
  black: {
    type: Boolean,
    default: () => false,
  },
  fullBlack: {
    type: Boolean,
    default: () => false,
  },
  red: {
    type: Boolean,
    default: () => false,
  },
  fullRed: {
    type: Boolean,
    default: () => false,
  },
  w100p: {
    type: Boolean,
    default: () => false,
  },
  w40p: {
    type: Boolean,
    default: () => false,
  },
  inlineStyle: {
    type: Object,
    default: () => ({}),
  },
  variant1: {
    type: Boolean,
    default: false,
  },
  variant2: {
    type: Boolean,
    default: false,
  },
  variant3: {
    type: Boolean,
    default: false,
  },
  variant4: {
    type: Boolean,
    default: false,
  },
  variant5: {
    type: Boolean,
    default: false,
  },
  variant6: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  paymentDone: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  &.button--loading {
    & .btn-text {
      visibility: hidden;
      opacity: 0;
    }
    &::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid transparent;
      border-top-color: #00cdd7;
      border-bottom-color: #00cdd7;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
  }
  &.button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  position: relative;
  padding: 12px 16px;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #00cdd7;
  background: #fff;
  /* outline: #00cdd7; */
  color: #00cdd7;
  cursor: pointer;
  transition: all 0.4s;

  &:active:enabled:not(:disabled) {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      #00cdd7;
    border: 2px solid transparent;
    outline: 2px solid transparent;
    & .btn-text {
      filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(10%)
        hue-rotate(158deg) brightness(102%) contrast(104%);
    }
  }
  &:not(:disabled):hover {
    color: #fff;
    background: #00b9c1;
    border: 2px solid transparent;

    & .btn-text {
      filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(10%)
        hue-rotate(158deg) brightness(102%) contrast(104%);
    }
  }
}
.action {
  background: #00cdd7;
  color: #ffffff;
  height: 100%;
  &:active:enabled {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      #00cdd7;
    border: 2px solid transparent;
  }
  &:not(:disabled):hover {
    background: #00b9c1;
    border: 2px solid transparent;
  }
}
.w100p {
  width: 100%;
}
.w40p {
  width: 40%;
}

.off,
[disabled] {
  background: #eeeff2;
  color: #bcbbbb;
  border: none !important;
  outline: none;
  cursor: not-allowed;
}
.btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  white-space: nowrap;
  font-style: normal;
  user-select: none;
  font: 500 14px/130% "Inter", sans-serif;
  button:not(:disabled) &:hover {
    filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(10%)
      hue-rotate(158deg) brightness(102%) contrast(104%);
  }
}
.variant1 {
  padding: 8px 20px;
  &:hover:enabled {
    background: #00b9c1;
    border: 2px solid transparent;
    outline: 2px solid transparent;
  }
  &:active:enabled {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      #00cdd7;
  }
}
.black {
  height: 45px;
  box-sizing: border-box;
  border: 2px solid #0e0e22;
  outline: 2px solid transparent;
  background: transparent;
  outline: #0e0e22;
  text-align: left;
  font: 500 14px/130% "Inter", sans-serif;
  color: #0e0e22;

  &.hover {
    background: #202033 !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #fff !important;
    border: 2px solid transparent;
    outline: 2px solid transparent;
    svg {
      stroke: #fff;
    }
  }

  &:hover:not(:disabled) {
    background: #202033 !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #fff !important;
    border: 2px solid transparent;
    outline: 2px solid transparent;
  }
  &:active:not(:disabled) {
    background: linear-gradient(
      302deg,
      #0e0e22 14.21%,
      #3c3c4c 111.51%
    ) !important;
    color: #fff !important;
  }
  &:disabled {
    background: #ebeaea;
    color: #bcbbbb;
  }
}
.payment-done {
  height: 45px;
  box-sizing: border-box;
  border: 2px solid #00eebb;
  outline: 2px solid transparent;
  text-align: left;
  font: 500 14px/130% "Inter", sans-serif;
  background: #00eebb;
  color: white;

  /* outline: 2px solid transparent; */
  /* background: transparent; */
  // &.hover {
  //   background: #fff !important;
  //   box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
  //   color: #00eebb !important;
  //   border: 2px solid transparent;
  //   outline: 2px solid transparent;
  //  svg {
  //    stroke: #00eebb;
  //  }
  //}

  /* &:hover:not(:disabled) {
    background: #fff !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #00eebb !important;
    border: 2px solid transparent;
    outline: 2px solid transparent;
  }

  &:active:not(:disabled) {
    background: #00eebb !important;
    color: #fff !important;
  } */

  &:disabled {
    background: #ebeaea;
    color: #bcbbbb;
  }
}
.full-black {
  height: 45px;
  box-sizing: border-box;
  border: 2px solid #0e0e22;
  outline: 2px solid transparent;
  background: #0e0e22;
  outline: #0e0e22;
  text-align: left;
  font: 500 14px/130% "Inter", sans-serif;
  color: #ffffff;
  &:hover:not(:disabled) {
    background: #202033 !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #fff !important;
    border: 2px solid transparent;
    outline: 2px solid transparent;
  }
  &:active:not(:disabled) {
    background: linear-gradient(
      302deg,
      #0e0e22 14.21%,
      #3c3c4c 111.51%
    ) !important;
    color: #fff !important;
  }
  &:disabled {
    background: #bcbbbb;
  }
  &:disabled:hover {
    background: #bcbbbb;
  }
}
.red {
  box-sizing: border-box;
  border: 2px solid #fe4a49;
  /* outline: 2px solid #fe4a49; */
  background: transparent;
  text-align: left;
  font: 500 14px/130% "Inter", sans-serif;
  color: #fe4a49;
  &:hover:not(:disabled) {
    background: #fe4a49 !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #fff !important;
    border: 2px solid transparent;
    /* outline: 2px solid transparent; */
  }
  &:active:not(:disabled) {
    background: linear-gradient(
      302deg,
      #fe4a49 14.21%,
      #d96060 111.51%
    ) !important;
    color: #fff !important;
  }
  &:disabled {
    background: #ebeaea;
    color: #bcbbbb;
    border: 2px solid transparent !important;
    /* outline: 2px solid transparent; */
  }
}
.full-red {
  box-sizing: border-box;
  border: 2px solid #fe4a49;
  outline: 2px solid transparent;
  background: #fe4a49;
  outline: #fe4a49;
  text-align: left;
  font: 500 14px/130% "Inter", sans-serif;
  color: #fff;
  &:hover {
    background: #fe4a49 !important;
    box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04) !important;
    color: #fff !important;
    border: 2px solid transparent;
    outline: 2px solid transparent;
  }
  &:active {
    background: linear-gradient(
      302deg,
      #fe4a49 14.21%,
      #d96060 111.51%
    ) !important;
    color: #fff !important;
  }
}
.variant2 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.variant5 {
  padding: 12px 20px;
  border-radius: 8px;
  padding: 13px 20px;
  max-width: 65px;
  flex-basis: 65px;
}
.variant6 {
  padding: 16px;
}
</style>
