<template>
  <div v-bind="$attrs"
class="cell">
    <div
      v-if="!noTitle"
      class="cell--title"
      :class="{ 'flex-column': isColumn }"
    >
      <div class="cell--title__wrapper"
style="height: 16px">
        <slot name="caption">
          <div :class="['cell--caption', { 'cell--disabled': disabled }]">
            {{ caption }}
          </div>
        </slot>
        <div v-if="required"
class="cell--caption red">*</div>
      </div>
      <div
        :class="['cell--desc', { 'cell--disabled': disabled }]"
        :title="desc"
      >
        {{ desc }}
      </div>
    </div>
    <div
      v-bind="$attrs"
      :class="['cell--content', { 'cell--disabled': disabled }]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  caption: {
    type: String,
    default: "",
  },
  desc: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  noTitle: {
    type: Boolean,
    default: () => false,
  },
  isColumn: {
    type: Boolean,
    default: () => false,
  },
});
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.cell--desc {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
